import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdalLoginService } from 'src/app/services/adal-login.service';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
})
export class WelcomePageComponent implements OnInit {
  public termsAccepted: boolean = false;

  constructor(private loginService: AdalLoginService, private router: Router) { }
  
  ngOnInit(): void {}

  public login(): void {
    localStorage.setItem('termsAccepted', 'true');
    this.router.navigate(['postauth']);
  }
}
