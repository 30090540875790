import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-section',
  templateUrl: './map-section.component.html',
  styleUrls: ['./map-section.component.scss'],
})
export class MapSectionComponent implements OnInit {
  public countryColorAndFocus: string = '#024266';
  public countryDataIndex: number = 0;
  public dataIndex: number = 0;
  constructor() {}
  @Input() mapBarData : any;

  ngOnInit(): void {}

  public setselectedContinental(element, index): void {
    this.dataIndex = index;
    document
      .querySelectorAll('.country-area')
      .forEach((countryElement: HTMLElement) => {
        countryElement.classList.remove('focused');
      });
      if(element.length) {
        element.forEach(el => el.classList.add('focused'));
      } else {
        element.classList.add('focused');
      }
  }
  public showPresentageChages(index: number): void {
    this.countryDataIndex = index;
  }
}
