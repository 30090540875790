import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdalLoginService } from '../services/adal-login.service';
import { fadeInOut } from './animationsSplash';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
  animations: [fadeInOut],
})
export class SplashComponent implements OnInit {
  public token: string;

  constructor(
    private router : Router,
  ) {}

  ngOnInit(): void {
  }

  login() {
    if (localStorage.getItem('termsAccepted') === 'true') {
      // if (this.isLoggedIn()) {
        this.router.navigate(['postauth']);
      // } else {
      //   this.loginService.login();
      // }
    }
    else {
      this.router.navigate(['welcome']);
    }

    // this.router.navigate(['postauth']);




  }

}
