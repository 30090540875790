import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'handleChangesForMultibar'
})
export class HandleChangesForMultibarPipe implements PipeTransform {

  transform(value: number, chartSectionType : string = ''): string {
    let valueAbs : number = Math.abs(value);
    if(value > 0){
      if (chartSectionType === '+') {
      return chartSectionType + valueAbs ;
      }else {
        return valueAbs + chartSectionType;
      }
    }else {
      if (chartSectionType === '+') {
        return valueAbs + chartSectionType;
      
      }else {
        return valueAbs + chartSectionType;

      }
    }
  }

}
