import { Component } from '@angular/core';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyDXk4Zy8HRAlVi4IcdcA5yKKDI7RUCTJj4",
  authDomain: "icl-id-rtba.firebaseapp.com",
  projectId: "icl-id-rtba",
  storageBucket: "icl-id-rtba.appspot.com",
  messagingSenderId: "915018253715",
  appId: "1:915018253715:web:9a95c0739110b7f6ba5466",
  measurementId: "G-MVL2CH7E68"
}

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

isSupported().then(result => {
  console.log('analytics supported');
  logEvent(analytics, 'vibes_page_view');
})

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor() {

    // window.screen.orientation.lock('portrait').then(() => {
    //   console.log('orientation locked');
    // }).catch(error => {
    //   console.log('cannot lock orientation');
    // });
  }


  title = 'RTBA';
}
