import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { IStockChartData, IStocksData } from '../../../app/model/data.model';

import * as echarts from 'echarts';
import { EChartsOption } from 'echarts';
import { StockDataService } from 'src/app/services/stock-data.service';

@Component({
  selector: 'app-stocks-panel',
  templateUrl: './stocks-panel.component.html',
  styleUrls: ['./stocks-panel.component.scss'],
})
export class StocksPanelComponent implements OnInit, AfterViewInit {
  public stocksData$: Observable<IStocksData[]>;
  public choosenStock: number;
  public options: EChartsOption;
  public myChart: any;
  public stockDataChart$: Observable<IStockChartData>;
  public stockDataChartSub: Subscription;
  private choosenStockDayChange: number;

  constructor(
    private stockData: DataService,
    private stockDataService: StockDataService
  ) {}

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.myChart = echarts.init(document.getElementById('stockChartICL'));

      this.stockDataChart$.subscribe((data: IStockChartData) => {
        if (data) {
          const upColor = '#00b8d8';
          const downColor = '#ff6f74';
          let xAxisData: number[] = [];
          for (let i = 0; i < data.values.length; i++) {
            xAxisData.push(i);
          }
          this.myChart.setOption({
            xAxis: {
              inverse: true,
              data: xAxisData,
            },
            yAxis: {
              type: 'value',
              min: +data.lowRate * 0.95,
              max: +data.maxRate * 1.05,
            },
            series: [
              {
                data: data.values,
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color:
                        this.choosenStockDayChange >= 0
                          ? upColor + '50'
                          : downColor + '50',
                    },
                    {
                      offset: 1,
                      color: '#002949',
                    },
                  ]),
                },
                lineStyle: {
                  color: this.choosenStockDayChange >= 0 ? upColor : downColor,
                  width: 2,
                },
              },
            ],
          });
          if (data.values.length > 0) {
            const findHeightOfLastRate =
              ((290 * ((+data.maxRate - data.values[1]) / +data.maxRate)) /
                100) *
              (data.maxRate - data.lowRate);
            this.myChart.dispatchAction({
              type: 'showTip',
              y: findHeightOfLastRate,
              x: this.myChart.getWidth() - 54,
            });
          } else {
            this.myChart.dispatchAction({ type: 'hideTip' });
          }
        }
      });

      this.stocksData$.subscribe((data) => {
        if (data) {
          this.showChartByStock(data[0]);
          this.choosenStock = data[0].stockId;
          this.createStockChartByStockId(this.choosenStock);
        }
      });
    });
  }

  public ngOnInit(): void {
    this.stockDataService.getStocks();
    this.stocksData$ = this.stockDataService.normalizedStocksData$;
    this.stockDataChart$ = this.stockDataService.stockChartData$;

    this.options = {
      tooltip: {
        backgroundColor: '#002949',
        borderColor: '#1A4F69',
        borderWidth: 2,
        borderRadius: 6,
        padding: 0,
        extraCssText: 'box-shadow: 0 0 0 rgba(0, 0, 0, 0.0);',
        trigger: 'axis',
        position: function (pos) {
          return [pos[0] - 35, pos[1] - 46];
        },
        formatter:
          '<div style="padding: 5px;" class="tooltip-stock-container"><div class="triangle"></div><span>{c}</span></div>',
        textStyle: {
          fontSize: 14,
          color: '#CFDADB',
          fontFamily: 'Demo Light',
        },
        axisPointer: {
          lineStyle: {
            opacity: 0,
          },
        },
      },
      xAxis: [
        {
          show: false,
          data: [],
        },
      ],

      yAxis: [
        {
          scale: true,
          type: 'value',
          splitNumber: 3,
          axisLabel: {
            showMinLabel: false,
          },
          axisLine: {
            lineStyle: {
              color: '#40697f',
            },
          },
          splitLine: {
            lineStyle: {
              color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                {
                  offset: 0,
                  color: '#002949',
                },
                {
                  offset: 0.5,
                  color: '#025579',
                },
                {
                  offset: 1,
                  color: '#002949',
                },
              ]),
            },
          },
          position: 'right',
        },
      ],
      grid: {
        left: 10,
        top: 10,
        right: 50,
        bottom: 0,
      },
      series: [
        {
          type: 'line',
          showSymbol: false,
          data: [],
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#FF6F7450',
              },
              {
                offset: 1,
                color: '#002949',
              },
            ]),
          },
          lineStyle: {
            color: '#FF6F74',
            width: 2,
          },
        },
      ],
    };
  }

  public showChartByStock(stock: IStocksData, index?: number): void {
    this.choosenStock = stock.stockId;
    this.choosenStockDayChange = stock.dayChange;
    this.createStockChartByStockId(this.choosenStock);
  }

  public createStockChartByStockId(stockId: number): void {
    this.stockDataService.setStockOnChart(stockId);
  }
}
