import { Component, Input, OnInit } from '@angular/core';
import { GraphData } from '../model/data.MODEL';

@Component({
  selector: 'app-top-clients-section',
  templateUrl: './top-clients-section.component.html',
  styleUrls: ['./top-clients-section.component.scss'],
})
export class TopClientsSectionComponent implements OnInit {
  @Input() horizontalBarData!: GraphData;

  constructor() {}

  ngOnInit(): void {}
}
