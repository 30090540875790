<div class="horizontal-bar" [class.selected]="selected">
  <div class="graph-line">
      <div class="bar" [ngStyle]="{'min-width.%' : item.width * .75}">
        <span class="title">{{item.customer_name}}</span>
        <div class="graphic" [ngStyle]="{'width.%' : item.width * .75}" ></div>
      </div>
      <div class="number">
        {{item.quantity}}
      </div>
  </div>
  <div class="data-line">
      <div class="data-cols">
        <div class="data-col">
          <div class="title">
            KMT
          </div>
          <div class="data">
            {{item.kmt}}
          </div>
        </div>
        <div class="data-col">
          <div class="title">
            Price $/MT
          </div>
          <div class="data">
            {{item.price}}
          </div>
        </div>
      </div>
  </div>
</div>