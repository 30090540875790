import { Component, Input, OnInit } from '@angular/core';
import { GraphData } from 'src/app/model/data.MODEL';

@Component({
  selector: 'app-editba-section',
  templateUrl: './editba-section.component.html',
  styleUrls: ['./editba-section.component.scss'],
})
export class EditbaSectionComponent implements OnInit {
  @Input() verticalBarData!: GraphData;

  constructor() {}

  ngOnInit(): void {}
}
