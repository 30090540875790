<div class="bar-chart-container" [ngClass]="{'positive' : recordData.change > 0 , 'negative' : recordData.change < 0}">
    <div class="bar-item-container" *ngIf="recordData; else noData">
       <div class="width-100-and-flex">
        <div [hidden]="recordData.change > 0"  class="bar-item" [style.width]="getChangeWith(recordData.change) + '%'"></div>
        <span *ngIf="recordData.change < 0">{{recordData.change | handleChangesForMultibar:symbolForBar}}</span>  
    </div>
        <div class="horizontal-axis"></div>
        <div class="width-100-and-flex">
            <div [hidden]="recordData.change < 0" class="bar-item" [style.width]="getChangeWith(recordData.change) + '%'"></div>
            <span *ngIf="recordData.change > 0">{{recordData.change | handleChangesForMultibar:symbolForBar}}</span>
        </div>
    </div>
</div>
    <ng-template #noData>
        <span>no data...</span>
    </ng-template>