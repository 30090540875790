import { Component, Input, OnInit } from '@angular/core';
import { GraphData } from 'src/app/model/data.MODEL';

@Component({
  selector: 'app-top-products-section',
  templateUrl: './top-products-section.component.html',
  styleUrls: ['./top-products-section.component.scss'],
})
export class TopProductsSectionComponent implements OnInit {
  @Input() horizontalBarData!: GraphData;

  constructor() {
  }
  
  ngOnInit(): void {
  }
}
