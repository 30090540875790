import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { GraphData } from 'src/app/model/data.MODEL';
import * as echarts from 'echarts';

@Component({
  selector: 'app-pie-graph-rtba',
  templateUrl: './pie-graph-rtba.component.html',
  styleUrls: ['./pie-graph-rtba.component.scss'],
})
export class PieGraphRTBAComponent implements OnInit {
  @Input() data!: GraphData;
  @Input() chartId: string = '';
  selectedItem: any = null;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  public onChartInit(ec) {
    //this.myChart = ec;
    setTimeout(() => {
      this.eventsOnBarChart(ec);
    });
  }

  public eventsOnBarChart(ec): void {
    const myChart = ec;
    let defaultBarIndex =
      myChart
        .getOption()
        .series[0].data.filter((item) => !item.item.isOverMonth).length - 1;
    this.selectedItem =
      myChart.getOption().series[0].data[defaultBarIndex].item;
    // console.log(this.selectedItem.item);
    this.cdr.detectChanges();
    // myChart.dispatchAction({ type: 'downplay', dataIndex: defaultBarIndex});
    myChart.dispatchAction({ type: 'highlight', dataIndex: defaultBarIndex });

    myChart.on('hightlight', (params) => {
      this.selectedItem = (params.data as any).item;
    });

    myChart.on('mousedown', (params) => {
      this.selectedItem = (params.data as any).item;
      myChart.dispatchAction({
        type: 'downplay',
      });
      if (params.dataIndex !== undefined) {
        myChart.dispatchAction({
          type: 'highlight',
          dataIndex: params.dataIndex,
        });
      } else {
        myChart.dispatchAction({
          type: 'highlight',
          dataIndex: defaultBarIndex,
        });
      }
    });

    // myChart.on('click', (params) => {
    //   this.selectedItem = (params.data as any).item;
    //   if (params.dataIndex === 0)
    //     myChart.dispatchAction({ type: 'highlight', dataIndex: 0, seriesName: 'Capex' });
    //   else
    //     myChart.dispatchAction({ type: 'downplay', dataIndex: 0, seriesName: 'Capex' });
    //   myChart.dispatchAction({
    //     type: 'selected',
    //     seriesIndex: params.currentIndex,
    //     dataIndex: params.currentIndex,
    //   });
    // });
    // setTimeout(() => {
    //   const option = myChart.getOption();
    //   myChart.dispatchAction({ type: 'highlight', dataIndex: 0, seriesName: 'Capex' });
    //   this.selectedItem = option.series[0].data[0].item;
    //   // console.log(this.selectedItem)
    // })
  }
}
