<mat-toolbar  color="primary" *ngIf="data.mobileType === 'android'" class="android-prompt">
    <button mat-button (click)="installPwa()">
       <img src="./assets/ICL_LOGO_APP.svg" alt="app icon rtba" />
       <span> Click here to download RTBA app.</span>
    </button>
    <button class="close" mat-icon-button (click)="close()">
    </button>
</mat-toolbar>

<div *ngIf="data.mobileType === 'ios'" class="ios-prompt">
    <button class="close-ios-inv" mat-button color="primary" (click)="close()">
    </button>
    <div><img src="./assets/ios-menu-btn.svg">
    </div>
    <span>To install this web app on your device tap the Menu button and then 'Add to Home screen' button</span>
</div>