import { Component, Input, OnInit } from '@angular/core';
import { GraphData } from '../../model/data.MODEL';

@Component({
  selector: 'app-io-graph',
  templateUrl: './io-graph.component.html',
  styleUrls: ['./io-graph.component.scss'],
})
export class IoGraphComponent implements OnInit {
  @Input() verticalBarData!: GraphData;

  constructor() {}

  ngOnInit(): void {}
}
