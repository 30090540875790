export const environment = {
  production: true,
  adalConfig: {
    tenant: '802762d2-02c4-4677-98ba-54060a234204',
    clientId: '4331a39e-68e4-4ab8-b58b-66e0e9ad4168',
    // expireOffsetSeconds: 320,//15 minutes (60-15 = 45 minutes auth)
    accessTokenLifetime: 1000,
    //redirectUri: 'http://localhost:4200/',
    popUp: false,
    cacheLocation: 'localStorage',
    resource: 'https://graph.microsoft.com',
  },
  dataApiEndpoint : 'https://api.rtba1-dev.apps4.icldig.icl-group.com/data/all',
  stocksAPIUrl : 'https://externalapi.bizportal.co.il/DataApi/RequestHandler?ClientId=164&RequestId=108&paperid={{paperId}}',
  histogramStockAPIUrl: `https://externalapi.bizportal.co.il/DataApi/RequestHandler?ClientId=164&RequestId=109&paperid={{paperId}}&dateFrom={{threeMonthsBefore}}&dateTo={{dateNow}}`,
};
