import { IDashboardData, GraphTypes, EVolume } from 'src/app/model/data.MODEL';
import * as echarts from 'echarts';

const GraphDataMock: IDashboardData | any = {
  divisions: [
    {
      division: 'ICL',
      data: [
        {
          name: 'Sales',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 0,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 170,
                              width: 1000,
                              height: 50,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 120, name: 19 },
                          { value: 160, name: 19 },
                          { value: 140, name: 19 },
                          { value: 80, name: 19 },
                          { value: 100, name: 19 },
                          { value: 20, name: 19 },
                          { value: 20, name: 19 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 120, name: 19 },
                          { value: 160, name: 19 },
                          { value: 140, name: 19 },
                          { value: 80, name: 19 },
                          { value: 100, name: 19 },
                          { value: 20, name: 19 },
                          { value: 20, name: 19 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
                {
                  id: 1,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 170,
                              width: 1000,
                              height: 50,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          padding: [0, 10, 0, 0],
                          formatter: ['{title|{c}}'].join('\n'),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#fff',
                              fontSize: 14,
                            },
                          },
                        },
                        data: [
                          { value: 23, name: 0 },
                          { value: 40, name: 2 },
                          { value: 54, name: -3 },
                          { value: 34, name: -8 },
                          { value: 67, name: 3 },
                          { value: 90, name: 5 },
                          { value: 78, name: 34 },
                        ],
                        type: 'bar',
                        barWidth: 14,
                        barGap: 0,

                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#fff',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            // shadowBlur: 16,
                            // shadowColor: 'rgba(255,255,255,0.4)',
                            // shadowOffsetX: -2,
                            // shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#006e9c',
                                },
                                {
                                  offset: 1,
                                  color: '#025579',
                                },
                              ]
                            ),
                          },
                        },
                      },
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          padding: [0, 0, 0, 10],
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 100, name: 2 },
                          { value: 40, name: 0 },
                          { value: 100, name: -30 },
                          { value: 20, name: -10 },
                          { value: 20, name: 4 },
                          { value: 30, name: 10 },
                          { value: 120, name: 300 },
                        ],
                        type: 'bar',
                        barWidth: 14,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 6,
                            shadowColor: 'rgba(0,0,0,0.3)',
                            shadowOffsetX: -2,
                            shadowOffsetY: -1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [
                {
                  id: 2,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 170,
                              width: 1000,
                              height: 50,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 10, name: 10 },
                          { value: 60, name: -10 },
                          { value: 40, name: 20 },
                          { value: 100, name: 100 },
                          { value: 100, name: -40 },
                          { value: 30, name: 0 },
                          { value: 60, name: 5 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
                {
                  id: 3,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 170,
                              width: 1000,
                              height: 50,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 50, name: 2 },
                          { value: 60, name: -10 },
                          { value: 60, name: 10 },
                          { value: 100, name: 10 },
                          { value: 10, name: 5 },
                          { value: 200, name: -10 },
                          { value: 100, name: 20 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
        {
          name: 'OI',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 0,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 170,
                              width: 1000,
                              height: 50,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                      {
                        type: 'category',
                        data: [2012, 2013],
                        position: 'bottom',
                        offset: 20,
                        axisLine: {
                          show: false,
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 120, name: 19 },
                          { value: 160, name: 19 },
                          { value: 140, name: 19 },
                          { value: 80, name: 19 },
                          { value: 100, name: 19 },
                          { value: 20, name: 19 },
                          { value: 20, name: 19 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
                {
                  id: 1,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 170,
                              width: 1000,
                              height: 50,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 100, name: 2 },
                          { value: 40, name: 0 },
                          { value: 100, name: -30 },
                          { value: 20, name: -10 },
                          { value: 20, name: 4 },
                          { value: 30, name: 10 },
                          { value: 120, name: 300 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [
                {
                  id: 2,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 186,
                              width: 1000,
                              height: 30,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 10, name: 10 },
                          { value: 60, name: -10 },
                          { value: 40, name: 20 },
                          { value: 100, name: 100 },
                          { value: 100, name: -40 },
                          { value: 30, name: 0 },
                          { value: 60, name: 5 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
                {
                  id: 3,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 186,
                              width: 1000,
                              height: 30,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 50, name: 2 },
                          { value: 60, name: -10 },
                          { value: 60, name: 10 },
                          { value: 100, name: 10 },
                          { value: 10, name: 5 },
                          { value: 200, name: -10 },
                          { value: 100, name: 20 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
        {
          name: 'EDITBA',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 0,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 170,
                              width: 1000,
                              height: 50,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                      {
                        type: 'category',
                        data: [2012, 2013],
                        position: 'bottom',
                        offset: 20,
                        axisLine: {
                          show: false,
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 120, name: 19 },
                          { value: 160, name: 19 },
                          { value: 140, name: 19 },
                          { value: 80, name: 19 },
                          { value: 100, name: 19 },
                          { value: 20, name: 19 },
                          { value: 20, name: 19 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
                {
                  id: 1,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 170,
                              width: 1000,
                              height: 50,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 100, name: 2 },
                          { value: 40, name: 0 },
                          { value: 100, name: -30 },
                          { value: 20, name: -10 },
                          { value: 20, name: 4 },
                          { value: 30, name: 10 },
                          { value: 120, name: 300 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [
                {
                  id: 2,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 186,
                              width: 1000,
                              height: 30,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 10, name: 10 },
                          { value: 60, name: -10 },
                          { value: 40, name: 20 },
                          { value: 100, name: 100 },
                          { value: 100, name: -40 },
                          { value: 30, name: 0 },
                          { value: 60, name: 5 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
                {
                  id: 3,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 186,
                              width: 1000,
                              height: 30,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 50, name: 2 },
                          { value: 60, name: -10 },
                          { value: 60, name: 10 },
                          { value: 100, name: 10 },
                          { value: 10, name: 5 },
                          { value: 200, name: -10 },
                          { value: 100, name: 20 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
        {
          name: 'CF',
          moreData: [
            { name: 'I.O', value: '62' },
            { name: 'DEP', value: '38' },
            { name: 'WC', value: '(20)' },
            { name: 'CAPEX', value: '30' },
            { name: 'CAPEX', value: '(12)' },
          ],
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 56786,
                  title: 'some slider',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'cf',
                    name: 'cf graph',
                    description: 'showing data about CF',
                  },
                  options: {
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 170,
                              width: 1000,
                              height: 50,
                            },
                            style: {
                              fill: '#002949',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                      {
                        type: 'category',
                        data: [2012, 2013],
                        position: 'bottom',
                        offset: 20,
                        axisLine: {
                          show: false,
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 40, name: 2 },
                          { value: 20, name: 0 },
                          { value: 40, name: -30 },
                          { value: 20, name: -10 },
                          { value: 20, name: 4 },
                          { value: 30, name: 10 },
                          { value: 60, name: 300 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [
                {
                  id: 978633,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 186,
                              width: 1000,
                              height: 30,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 10, name: 10 },
                          { value: 60, name: -10 },
                          { value: 40, name: 20 },
                          { value: 100, name: 100 },
                          { value: 100, name: -40 },
                          { value: 30, name: 0 },
                          { value: 60, name: 5 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
                {
                  id: 5464,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 186,
                              width: 1000,
                              height: 30,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 50, name: 2 },
                          { value: 60, name: -10 },
                          { value: 60, name: 10 },
                          { value: 100, name: 10 },
                          { value: 10, name: 5 },
                          { value: 200, name: -10 },
                          { value: 100, name: 20 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
        {
          name: 'Top 10 Products',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 7,
                  title: 'some slider',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'Top 10 Products',
                    name: 'Top 10 Products graph',
                    description: 'showing data about Top 10 Products',
                  },
                  options: {
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    xAxis: [
                      {
                        show: false,
                        type: 'value',
                        boundaryGap: [0, 0.2],
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'category',
                        position: 'left',
                        data: [
                          'POSTASH',
                          'GTSP',
                          'GFAC 4D',
                          'MAP',
                          'GSSP',
                          'ZIN',
                          'NPS',
                          'GNPK',
                          'MCP',
                        ],
                        axisLine: {
                          show: false,
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        axisLabel: {
                          inside: true,
                          textStyle: {
                            color: '#ffffff50',
                          },
                        },
                        z: 10,
                        splitLine: {
                          show: false,
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 0,
                      top: 10,
                      right: 0,
                      bottom: 10,
                    },
                    series: [
                      {
                        label: {
                          show: true,
                          position: 'right',
                          valueAnimation: true,
                          fontSize: 12,
                          formatter: function (obj) {
                            return [`{title| ${obj.value}}`].join('\n');
                          },
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#ffffff50',
                              fontSize: 12,
                              padding: 8,
                            },
                            ktm: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                            smt: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 75, KMT: 1548, SMT: 425 },
                          { value: 80, KMT: 1548, SMT: 425 },
                          { value: 90, KMT: 1548, SMT: 425 },
                          { value: 100, KMT: 1548, SMT: 425 },
                          { value: 150, KMT: 1548, SMT: 425 },
                          { value: 160, KMT: 1548, SMT: 425 },
                          { value: 180, KMT: 1548, SMT: 425 },
                          { value: 200, KMT: 1548, SMT: 425 },
                          { value: 233, KMT: 1548, SMT: 425 },
                        ],
                        type: 'bar',
                        barCategoryGap: '40%',
                        emphasis: {
                          label: {
                            show: true,
                            rich: {
                              title: {
                                fontFamily: 'Demo Regular',
                                align: 'center',
                                fontWeight: '100',
                                color: '#01d9eb',
                                fontSize: 24,
                                padding: 8,
                              },
                            },
                            formatter: function (obj) {
                              return [
                                `{title| ${obj.value}}`,
                                // `{ktm| ${obj.value}} {smt| ${obj.value}}`,
                              ].join('\n');
                            },
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              1,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
                {
                  id: 723,
                  title: 'hitmap slider',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'Top 10 Products - hitmap',
                    name: 'Top 10 Products graph - hitmap',
                    description: 'showing data about Top 10 Products on hitmap',
                  },
                  options: {
                    textStyle: {
                      fontSize: 14,
                      fontFamily: 'Demo Regular',
                    },
                    xAxis: [
                      {
                        show: false,
                        type: 'value',
                        boundaryGap: [0, 0.2],
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'category',
                        axisLine: {
                          show: false,
                        },
                        splitLine: {
                          show: false,
                        },
                      },
                    ],
                    grid: {
                      left: 0,
                      top: 0,
                      right: 0,
                      bottom: 0,
                    },
                    series: [
                      {
                        itemStyle: {
                          borderColor: '#002949',
                          borderWidth: 2,
                        },
                        breadcrumb: {
                          show: false,
                        },
                        roam: false,
                        nodeClick: false,
                        type: 'treemap',
                        label: {
                          color: '#ffffff',
                          fontSize: 16,
                          formatter: function (params) {
                            return params.name + ' \n' + params.value;
                          },
                        },
                        width: '100%',
                        height: '100%',
                        data: [
                          {
                            name: 'POTASH', // First tree
                            value: 233,

                            itemStyle: {
                              color: '#015578',
                              borderColor: '#002949',
                            },
                          },
                          {
                            name: 'GTSP', // First tree
                            value: 200,
                            itemStyle: {
                              color: '#015578',
                              borderColor: '#002949',
                            },
                          },
                          {
                            name: 'GPK', // First tree
                            value: 180,

                            itemStyle: {
                              color: '#015578',
                              borderColor: '#002949',
                            },
                          },
                          {
                            name: 'GPAC 4D', // First tree
                            value: 160,

                            itemStyle: {
                              color: '#015578',
                              borderColor: '#002949',
                            },
                          },
                          {
                            name: 'MAP', // First tree
                            value: 156,

                            itemStyle: {
                              color: '#015578',
                              borderColor: '#002949',
                            },
                          },
                          {
                            name: 'GSSP', // First tree
                            value: 150,

                            itemStyle: {
                              color: '#015578',
                              borderColor: '#002949',
                            },
                          },
                          {
                            name: 'ZIN', // First tree
                            value: 100,

                            itemStyle: {
                              color: '#015578',
                              borderColor: '#002949',
                            },
                          },
                          {
                            name: 'NPS', // First tree
                            value: 90,

                            itemStyle: {
                              color: '#015578',
                              borderColor: '#002949',
                            },
                          },
                          {
                            name: 'GNPK', // First tree
                            value: 80,

                            itemStyle: {
                              color: '#015578',
                              borderColor: '#002949',
                            },
                          },
                          {
                            name: 'MCP', // Second tree
                            value: 75,
                            itemStyle: {
                              color: '#015578',
                              borderColor: '#002949',
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [
                {
                  id: 2,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 186,
                              width: 1000,
                              height: 30,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 10, name: 10 },
                          { value: 60, name: -10 },
                          { value: 40, name: 20 },
                          { value: 100, name: 100 },
                          { value: 100, name: -40 },
                          { value: 30, name: 0 },
                          { value: 60, name: 5 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
                {
                  id: 3,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 186,
                              width: 1000,
                              height: 30,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 50, name: 2 },
                          { value: 60, name: -10 },
                          { value: 60, name: 10 },
                          { value: 100, name: 10 },
                          { value: 10, name: 5 },
                          { value: 200, name: -10 },
                          { value: 100, name: 20 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
        {
          name: 'Top 10 clients',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 8,
                  title: 'some slider',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'Top 10 clients',
                    name: 'Top 10 clients graph',
                    description: 'showing data about Top 10 clients',
                  },
                  options: {
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    xAxis: [
                      {
                        show: false,
                        type: 'value',
                        boundaryGap: [0, 0.2],
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'category',
                        position: 'left',
                        data: [
                          'INDIA POSTASH LIMITED',
                          'COOP INTERNAL CONSUMPTION',
                          'AMCCI IMPORTCAO',
                          'GROWMARK, INC',
                          'GUANGUDONG TINAHE AGR',
                          'ADUBOS SUDOESTE LTDA',
                          'MEHERIN - FERTILIER',
                          'AGROPECUARIA MAGGI',
                        ],
                        axisLine: {
                          show: false,
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        axisLabel: {
                          inside: true,
                          textStyle: {
                            color: '#ffffff50',
                            overflow: 'break',
                          },
                          formatter: function (obj: string, index: number) {
                            return index > 3 ? obj : obj.slice(0, 10) + '...';
                          },
                        },
                        z: 10,
                        splitLine: {
                          show: false,
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 0,
                      top: 10,
                      right: 0,
                      bottom: 10,
                    },
                    series: [
                      {
                        label: {
                          show: true,
                          position: 'right',
                          valueAnimation: true,
                          fontSize: 12,

                          formatter: function (obj) {
                            return [`{title| ${obj.value}}`].join('\n');
                          },
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#ffffff50',
                              fontSize: 12,
                              padding: 8,
                            },
                            ktm: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                            smt: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 75, KMT: 1548, SMT: 425 },
                          { value: 80, KMT: 1548, SMT: 425 },
                          { value: 90, KMT: 1548, SMT: 425 },
                          { value: 100, KMT: 1548, SMT: 425 },
                          { value: 150, KMT: 1548, SMT: 425 },
                          { value: 160, KMT: 1548, SMT: 425 },
                          { value: 180, KMT: 1548, SMT: 425 },
                          { value: 200, KMT: 1548, SMT: 425 },
                          { value: 233, KMT: 1548, SMT: 425 },
                        ],
                        type: 'bar',
                        barCategoryGap: '40%',
                        emphasis: {
                          label: {
                            show: true,
                            rich: {
                              title: {
                                fontFamily: 'Demo Regular',
                                align: 'center',
                                fontWeight: '100',
                                color: '#01d9eb',
                                fontSize: 24,
                                padding: 8,
                              },
                            },
                            formatter: function (obj) {
                              return [
                                `{title| ${obj.value}}`,
                                // `{ktm| ${obj.value}} {smt| ${obj.value}}`,
                              ].join('\n');
                            },
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              1,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [
                {
                  id: 10,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 186,
                              width: 1000,
                              height: 30,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 10, name: 10 },
                          { value: 60, name: -10 },
                          { value: 40, name: 20 },
                          { value: 100, name: 100 },
                          { value: 100, name: -40 },
                          { value: 30, name: 0 },
                          { value: 60, name: 5 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
                {
                  id: 3,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 186,
                              width: 1000,
                              height: 30,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 50, name: 2 },
                          { value: 60, name: -10 },
                          { value: 60, name: 10 },
                          { value: 100, name: 10 },
                          { value: 10, name: 5 },
                          { value: 200, name: -10 },
                          { value: 100, name: 20 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
        {
          name: 'Map',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 9,
                  title: 'some slider',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'Top 10 clients',
                    name: 'Top 10 clients graph',
                    description: 'showing data about Top 10 clients',
                  },
                  options: {
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    xAxis: [
                      {
                        show: false,
                        type: 'value',
                        boundaryGap: [0, 0.2],
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'category',
                        position: 'left',
                        data: [
                          'INDIA POSTASH LIMITED',
                          'COOP INTERNAL CONSUMPTION',
                          'AMCCI IMPORTCAO',
                          'GROWMARK, INC',
                          'GUANGUDONG TINAHE AGR',
                          'ADUBOS SUDOESTE LTDA',
                          'MEHERIN - FERTILIER',
                          'AGROPECUARIA MAGGI',
                        ],
                        axisLine: {
                          show: false,
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        axisLabel: {
                          inside: true,
                          textStyle: {
                            color: '#ffffff50',
                            overflow: 'truncate',
                            ellipsis: '...',
                          },

                          // formatter: function (obj: string, index: number) {
                          //   return obj;
                          // },
                        },
                        z: 10,
                        splitLine: {
                          show: false,
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 0,
                      top: 10,
                      right: 0,
                      bottom: 10,
                    },
                    series: [
                      {
                        label: {
                          show: true,
                          position: 'right',
                          valueAnimation: true,
                          fontSize: 12,
                        },
                        data: [
                          {
                            title: 'N.America',
                            countries: [
                              { name: 'Total', value: 233, presentage: 19 },
                              { name: 'CHharlson', value: 344, presentage: 19 },
                              { name: 'Denver', value: 533, presentage: 19 },
                              { name: 'California', value: 54, presentage: 19 },
                              { name: 'Detroit', value: 78, presentage: 19 },
                              { name: 'Detroit', value: 78, presentage: 19 },
                              { name: 'Detroit', value: 78, presentage: 19 },
                            ],
                          },
                          {
                            title: 'S.America',
                            countries: [
                              { name: 'Total', value: 548, presentage: 19 },
                              { name: 'CHharlson', value: 140, presentage: 19 },
                              { name: 'Denver', value: 120, presentage: 19 },
                              { name: 'California', value: 96, presentage: 19 },
                            ],
                          },
                          {
                            title: 'Europe',
                            countries: [
                              { name: 'Total', value: 233, presentage: 19 },
                              { name: 'CHharlson', value: 344, presentage: 19 },
                              { name: 'Denver', value: 533, presentage: 19 },
                              { name: 'California', value: 54, presentage: 19 },
                              { name: 'Detroit', value: 78, presentage: 19 },
                            ],
                          },
                          {
                            title: 'Asia',
                            countries: [
                              { name: 'Total', value: 233, presentage: 19 },
                              { name: 'CHharlson', value: 344, presentage: 19 },
                              { name: 'Denver', value: 533, presentage: 19 },
                              { name: 'California', value: 54, presentage: 19 },
                              { name: 'Detroit', value: 78, presentage: 19 },
                            ],
                          },
                          {
                            title: 'Africa',
                            countries: [
                              { name: 'Total', value: 233, presentage: 19 },
                              { name: 'CHharlson', value: 344, presentage: 19 },
                              { name: 'Denver', value: 533, presentage: 19 },
                              { name: 'California', value: 54, presentage: 19 },
                              { name: 'Detroit', value: 78, presentage: 19 },
                            ],
                          },
                          {
                            title: 'Australia',
                            countries: [
                              { name: 'Total', value: 233, presentage: 19 },
                              { name: 'CHharlson', value: 344, presentage: 19 },
                              { name: 'Denver', value: 533, presentage: 19 },
                              { name: 'California', value: 54, presentage: 19 },
                              { name: 'Detroit', value: 78, presentage: 19 },
                            ],
                          },
                        ],
                        type: 'bar',
                        barCategoryGap: '40%',
                        emphasis: {
                          label: {
                            show: true,
                            rich: {
                              title: {
                                fontFamily: 'Demo Regular',
                                align: 'center',
                                fontWeight: '100',
                                color: '#01d9eb',
                                fontSize: 24,
                                padding: 8,
                              },
                            },
                            formatter: function (obj) {
                              return [
                                `{title| ${obj.value}}`,
                                // `{ktm| ${obj.value}} {smt| ${obj.value}}`,
                              ].join('\n');
                            },
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              1,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [
                {
                  id: 10,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 186,
                              width: 1000,
                              height: 30,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 10, name: 10 },
                          { value: 60, name: -10 },
                          { value: 40, name: 20 },
                          { value: 100, name: 100 },
                          { value: 100, name: -40 },
                          { value: 30, name: 0 },
                          { value: 60, name: 5 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
        {
          name: 'Potash Prices',
          data: [],
        },
        {
          name: "Financial KPI's(YTD)",
          otherinfo: {
            value: '80%',
            title: 'Meeting the objective',
          },
          dataTable: [
            {
              title: '33%',
              description: 'Oparetion Income',
              ACT: 150,
              TGRT: 120,
              change: 33,
            },
            {
              title: '40%',
              description: 'Sales',
              ACT: 92,
              TGRT: 100,
              change: -8,
            },
            {
              title: '20%',
              description: 'CAPX',
              ACT: 176,
              TGRT: 200,
              change: -12,
            },
          ],
          slides: [
            {
              id: 87654,
              title: 'Financial KPI',
              data: {
                type: GraphTypes.BASETABLE,
                title: 'Financial KPI',
                name: 'Financial KPI',
                description: "Financial KPI's(YTD)",
              },
              options: {
                color: new echarts.graphic.LinearGradient(0, 0.5, 0.8, 0, [
                  { offset: 0, color: '#16657e' },
                  { offset: 0.5, color: '#00deef' },
                ]),
                series: [
                  {
                    itemStyle: {
                      color: '#01233c',
                    },
                    startAngle: 180,
                    endAngle: 360,
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                    data: [
                      { value: 10, name: 'data-a' },

                      {
                        value: 10,
                        name: null,
                        itemStyle: { opacity: 0 },
                        tooltip: { show: false },
                      },
                    ],
                  },
                  {
                    startAngle: 180,
                    endAngle: 360,
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                    data: [
                      { value: 7, name: 'data-a' },

                      {
                        value: 10,
                        name: null,
                        itemStyle: { opacity: 0 },
                        tooltip: { show: false },
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
        {
          name: 'OI by SBU',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 9,
                  title: 'some slider',
                  data: [
                    [
                      {
                        title: 'BR',
                        description: 'Oparetion Income',
                        ACT: 150,
                        TGRT: 120,
                        change: 33,
                      },
                      {
                        title: 'p',
                        description: 'Oparetion Income',
                        ACT: 150,
                        TGRT: 120,
                        change: 33,
                      },
                      {
                        title: 'sm',
                        description: 'Oparetion Income',
                        ACT: 150,
                        TGRT: 120,
                        change: 33,
                      },
                    ],
                    [
                      {
                        title: 'BR',
                        description: 'Oparetion Income',
                        ACT: 150,
                        TGRT: 120,
                        change: 33,
                      },
                      {
                        title: 'p',
                        description: 'Oparetion Income',
                        ACT: 150,
                        TGRT: 120,
                        change: 33,
                      },
                      {
                        title: 'sm',
                        description: 'Oparetion Income',
                        ACT: 150,
                        TGRT: 120,
                        change: 33,
                      },
                    ],
                    [
                      {
                        title: 'BR',
                        description: 'Oparetion Income',
                        ACT: 150,
                        TGRT: 120,
                        change: 33,
                      },
                      {
                        title: 'p',
                        description: 'Oparetion Income',
                        ACT: 150,
                        TGRT: 120,
                        change: 33,
                      },
                      {
                        title: 'sm',
                        description: 'Oparetion Income',
                        ACT: 150,
                        TGRT: 120,
                        change: 33,
                      },
                    ],
                    [
                      {
                        title: 'BR',
                        description: 'Oparetion Income',
                        ACT: 150,
                        TGRT: 120,
                        change: 33,
                      },
                      {
                        title: 'p',
                        description: 'Oparetion Income',
                        ACT: 150,
                        TGRT: 120,
                        change: 33,
                      },
                      {
                        title: 'sm',
                        description: 'Oparetion Income',
                        ACT: 150,
                        TGRT: 120,
                        change: 33,
                      },
                    ],
                  ],
                },
                {
                  id: 9,
                  title: 'some slider',
                  data: [
                    {
                      title: '33%',
                      description: 'Oparetion Income',
                      ACT: 150,
                      TGRT: 120,
                      change: 33,
                    },
                    {
                      title: '40%',
                      description: 'Sales',
                      ACT: 92,
                      TGRT: 100,
                      change: -8,
                    },
                    {
                      title: '20%',
                      description: 'CAPX',
                      ACT: 176,
                      TGRT: 200,
                      change: -12,
                    },
                  ],
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [
                {
                  id: 10,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 186,
                              width: 1000,
                              height: 30,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 10, name: 10 },
                          { value: 60, name: -10 },
                          { value: 40, name: 20 },
                          { value: 100, name: 100 },
                          { value: 100, name: -40 },
                          { value: 30, name: 0 },
                          { value: 60, name: 5 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
        {
          name: 'ICL FCF',
          dataTable: [
            {
              title: 'IP',
              change: 94,
            },
            {
              title: 'POTASH',
              change: -28,
            },
            {
              title: 'PS',
              change: -10,
            },
            {
              title: 'IAS',
              change: 6,
            },
            {
              title: 'OTHER',
              change: 3,
            },
            {
              title: 'OPERATIING FCF',
              change: 59,
            },
            {
              title: 'OPERATIING FCF',
              change: 59,
            },
            {
              title: 'TAX REFUND',
              change: 6,
            },
            {
              title: 'INTERST PAYMENT',
              change: -10,
            },
            {
              title: 'DERIVATIVES RECIEVD',
              change: 6,
            },
            {
              title: 'FREE CASH FLOW',
              change: 59,
            },
            {
              title: 'DIVIDEND',
              change: 34,
            },
          ],
        },
        {
          name: 'Potash avg.Price',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 0,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 170,
                              width: 1000,
                              height: 50,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                      // {
                      //   type: 'category',
                      //   data: [2012, 2013],
                      //   position: 'bottom',
                      //   offset: 20,
                      //   axisLine: {
                      //     show: false,
                      //   },
                      // },
                    ],
                    yAxis: [
                      {
                        splitNumber: 3,
                        type: 'value',
                        position: 'right',
                        axisLabel: {
                          formatter: function (obj: string, index: number) {
                            return +obj !== 0 ? obj : null;
                          },
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 120 },
                          { value: 160 },
                          { value: 140 },
                          { value: 80 },
                          { value: 100 },
                          { value: 20 },
                          { value: 20 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 140 },
                          { value: 180 },
                          { value: 200 },
                          { value: 90 },
                          { value: 120 },
                          { value: 40 },
                          { value: 60 },
                        ],
                        type: 'line',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                      {
                        symbolSize: 12,
                        symbol: 'emptyCircle',
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 180 },
                          { value: 220 },
                          { value: 240 },
                          { value: 130 },
                          { value: 160 },
                          { value: 80 },
                          { value: 100 },
                        ],
                        type: 'line',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [
                {
                  id: 2,
                  title: 'Potash avg',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    // tooltip: {
                    //   trigger: 'item',
                    //   formatter: '{c} <br/> {b}%',
                    // },
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 170,
                              width: 1000,
                              height: 50,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 10, name: 10 },
                          { value: 60, name: -10 },
                          { value: 40, name: 20 },
                          { value: 100, name: 100 },
                          { value: 100, name: -40 },
                          { value: 30, name: 0 },
                          { value: 60, name: 5 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
      ],
    },
    {
      division: 'IP',
      data: [
        {
          name: 'Sales IP',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 4,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                      },
                      {
                        type: 'category',
                        data: [2012, 2013],
                        position: 'bottom',
                        offset: 20,
                        axisLine: {
                          show: false,
                        },
                      },
                    ],
                    yAxis: {
                      type: 'value',
                      position: 'right',
                    },
                    series: [
                      {
                        data: [10, 10, 10, 10, 20, 20, 20],
                        type: 'bar',
                      },
                    ],
                    grid: { borderColor: '#ff00cf' },
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
        {
          name: 'Sales IP',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 4,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                      },
                      {
                        type: 'category',
                        data: [2012, 2013],
                        position: 'bottom',
                        offset: 20,
                        axisLine: {
                          show: false,
                        },
                      },
                    ],
                    yAxis: {
                      type: 'value',
                      position: 'right',
                    },
                    series: [
                      {
                        data: [10, 10, 10, 10, 20, 20, 20],
                        type: 'bar',
                      },
                    ],
                    grid: { borderColor: '#ff00cf' },
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
        {
          name: '11 IP',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 4534,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                      },
                      {
                        type: 'category',
                        data: [2012, 2013],
                        position: 'bottom',
                        offset: 20,
                        axisLine: {
                          show: false,
                        },
                      },
                    ],
                    yAxis: {
                      type: 'value',
                      position: 'right',
                    },
                    series: [
                      {
                        data: [10, 10, 10, 10, 20, 20, 20],
                        type: 'bar',
                      },
                    ],
                    grid: { borderColor: '#ff00cf' },
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
        {
          name: 'EBITDA',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 4534,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                      },
                      {
                        type: 'category',
                        data: [2012, 2013],
                        position: 'bottom',
                        offset: 20,
                        axisLine: {
                          show: false,
                        },
                      },
                    ],
                    yAxis: {
                      type: 'value',
                      position: 'right',
                    },
                    series: [
                      {
                        data: [10, 10, 10, 10, 20, 20, 20],
                        type: 'bar',
                      },
                    ],
                    grid: { borderColor: '#ff00cf' },
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
        {
          name: 'Top 10 clients',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 8,
                  title: 'some slider',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'Top 10 clients',
                    name: 'Top 10 clients graph',
                    description: 'showing data about Top 10 clients',
                  },
                  options: {
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    xAxis: [
                      {
                        show: false,
                        type: 'value',
                        boundaryGap: [0, 0.2],
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'category',
                        position: 'left',
                        data: [
                          'INDIA POSTASH LIMITED',
                          'COOP INTERNAL CONSUMPTION',
                          'AMCCI IMPORTCAO',
                          'GROWMARK, INC',
                          'GUANGUDONG TINAHE AGR',
                          'ADUBOS SUDOESTE LTDA',
                          'MEHERIN - FERTILIER',
                          'AGROPECUARIA MAGGI',
                        ],
                        axisLine: {
                          show: false,
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        axisLabel: {
                          inside: true,
                          textStyle: {
                            color: '#ffffff50',
                            overflow: 'break',
                          },
                          formatter: function (obj: string, index: number) {
                            return index > 3 ? obj : obj.slice(0, 10) + '...';
                          },
                        },
                        z: 10,
                        splitLine: {
                          show: false,
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 0,
                      top: 10,
                      right: 0,
                      bottom: 10,
                    },
                    series: [
                      {
                        label: {
                          show: true,
                          position: 'right',
                          valueAnimation: true,
                          fontSize: 12,

                          formatter: function (obj) {
                            return [`{title| ${obj.value}}`].join('\n');
                          },
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#ffffff50',
                              fontSize: 12,
                              padding: 8,
                            },
                            ktm: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                            smt: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 75, KMT: 1548, SMT: 425 },
                          { value: 80, KMT: 1548, SMT: 425 },
                          { value: 90, KMT: 1548, SMT: 425 },
                          { value: 100, KMT: 1548, SMT: 425 },
                          { value: 150, KMT: 1548, SMT: 425 },
                          { value: 160, KMT: 1548, SMT: 425 },
                          { value: 180, KMT: 1548, SMT: 425 },
                          { value: 200, KMT: 1548, SMT: 425 },
                          { value: 233, KMT: 1548, SMT: 425 },
                        ],
                        type: 'bar',
                        barCategoryGap: '40%',
                        emphasis: {
                          label: {
                            show: true,
                            rich: {
                              title: {
                                fontFamily: 'Demo Regular',
                                align: 'center',
                                fontWeight: '100',
                                color: '#01d9eb',
                                fontSize: 24,
                                padding: 8,
                              },
                            },
                            formatter: function (obj) {
                              return [
                                `{title| ${obj.value}}`,
                                // `{ktm| ${obj.value}} {smt| ${obj.value}}`,
                              ].join('\n');
                            },
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              1,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [
                {
                  id: 10,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 186,
                              width: 1000,
                              height: 30,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 10, name: 10 },
                          { value: 60, name: -10 },
                          { value: 40, name: 20 },
                          { value: 100, name: 100 },
                          { value: 100, name: -40 },
                          { value: 30, name: 0 },
                          { value: 60, name: 5 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
                {
                  id: 3,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    textStyle: {
                      fontSize: 6,
                      fontFamily: 'Demo Regular',
                    },
                    graphic: [
                      {
                        elements: [
                          {
                            id: 'small_circle',
                            type: 'rect',
                            shape: {
                              x: -10,
                              y: 186,
                              width: 1000,
                              height: 30,
                            },
                            style: {
                              fill: '#024266',
                              stroke: '#40697f',
                            },
                          },
                        ],
                      },
                    ],
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        splitLine: {},
                        axisLabel: {
                          backgroundColor: '#024266',
                        },
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                        position: 'right',
                        axisLine: {
                          lineStyle: {
                            color: '#40697f80',
                          },
                        },
                        splitLine: {
                          lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                              1,
                              0,
                              0,
                              0,
                              [
                                {
                                  offset: 0,
                                  color: '#002949',
                                },
                                {
                                  offset: 0.5,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#002949',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                    grid: {
                      left: 30,
                      top: -10,
                      right: 50,
                      bottom: 50,
                    },
                    series: [
                      {
                        label: {
                          show: false,
                          valueAnimation: true,
                          position: 'top',
                          fontSize: 12,
                          formatter: ['{title|{c}}', '{change|{b}%}'].join(
                            '\n'
                          ),
                          rich: {
                            title: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              fontWeight: 'bold',
                              color: '#00bdde',
                              fontSize: 14,
                            },
                            change: {
                              fontFamily: 'Demo Regular',
                              align: 'center',
                              color: '#00bdde',
                              fontSize: 10,
                            },
                          },
                        },
                        data: [
                          { value: 50, name: 2 },
                          { value: 60, name: -10 },
                          { value: 60, name: 10 },
                          { value: 100, name: 10 },
                          { value: 10, name: 5 },
                          { value: 200, name: -10 },
                          { value: 100, name: 20 },
                        ],
                        type: 'bar',
                        barWidth: 20,
                        emphasis: {
                          label: {
                            show: true,
                          },
                          itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#01d9eb',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                        itemStyle: {
                          normal: {
                            shadowBlur: 16,
                            shadowColor: 'rgba(0,0,0,0.06)',
                            shadowOffsetX: 1,
                            shadowOffsetY: 1,
                            color: new echarts.graphic.LinearGradient(
                              0,
                              0,
                              0,
                              1,
                              [
                                {
                                  offset: 0,
                                  color: '#025579',
                                },
                                {
                                  offset: 1,
                                  color: '#004765',
                                },
                              ]
                            ),
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
      ],
    },
    {
      division: 'POTASH',
      data: [
        {
          name: 'Sales',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 4,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                      },
                      {
                        type: 'category',
                        data: [2012, 2013],
                        position: 'bottom',
                        offset: 20,
                        axisLine: {
                          show: false,
                        },
                      },
                    ],
                    yAxis: {
                      type: 'value',
                      position: 'right',
                    },
                    series: [
                      {
                        data: [10, 10, 10, 10, 20, 20, 20],
                        type: 'bar',
                      },
                    ],
                    grid: { borderColor: '#ff00cf' },
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
        {
          name: 'Sales IP',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 4,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                      },
                      {
                        type: 'category',
                        data: [2012, 2013],
                        position: 'bottom',
                        offset: 20,
                        axisLine: {
                          show: false,
                        },
                      },
                    ],
                    yAxis: {
                      type: 'value',
                      position: 'right',
                    },
                    series: [
                      {
                        data: [10, 10, 10, 10, 20, 20, 20],
                        type: 'bar',
                      },
                    ],
                    grid: { borderColor: '#ff00cf' },
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
      ],
    },
    {
      division: 'PS',
      data: [
        {
          name: 'Sales',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 4,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                      },
                      {
                        type: 'category',
                        data: [2012, 2013],
                        position: 'bottom',
                        offset: 20,
                        axisLine: {
                          show: false,
                        },
                      },
                    ],
                    yAxis: {
                      type: 'value',
                      position: 'right',
                    },
                    series: [
                      {
                        data: [10, 10, 10, 10, 20, 20, 20],
                        type: 'bar',
                      },
                    ],
                    grid: { borderColor: '#ff00cf' },
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
        {
          name: 'Sales IP',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 4,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                      },
                      {
                        type: 'category',
                        data: [2012, 2013],
                        position: 'bottom',
                        offset: 20,
                        axisLine: {
                          show: false,
                        },
                      },
                    ],
                    yAxis: {
                      type: 'value',
                      position: 'right',
                    },
                    series: [
                      {
                        data: [10, 10, 10, 10, 20, 20, 20],
                        type: 'bar',
                      },
                    ],
                    grid: { borderColor: '#ff00cf' },
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
      ],
    },
    {
      division: 'SF',
      data: [
        {
          name: 'Sales',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 4,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                      },
                      {
                        type: 'category',
                        data: [2012, 2013],
                        position: 'bottom',
                        offset: 20,
                        axisLine: {
                          show: false,
                        },
                      },
                    ],
                    yAxis: {
                      type: 'value',
                      position: 'right',
                    },
                    series: [
                      {
                        data: [10, 10, 10, 10, 20, 20, 20],
                        type: 'bar',
                      },
                    ],
                    grid: { borderColor: '#ff00cf' },
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
        {
          name: 'Sales IP',
          nav: {
            M: {
              otherinfo: '',
              slides: [
                {
                  id: 4,
                  title: 'slide 1',
                  data: {
                    type: GraphTypes.BASETABLE,
                    title: 'graphdata 1',
                    name: 'name here 1',
                    description: 'description here 1',
                  },
                  options: {
                    xAxis: [
                      {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                      },
                      {
                        type: 'category',
                        data: [2012, 2013],
                        position: 'bottom',
                        offset: 20,
                        axisLine: {
                          show: false,
                        },
                      },
                    ],
                    yAxis: {
                      type: 'value',
                      position: 'right',
                    },
                    series: [
                      {
                        data: [10, 10, 10, 10, 20, 20, 20],
                        type: 'bar',
                      },
                    ],
                    grid: { borderColor: '#ff00cf' },
                  },
                },
              ],
            },
            QTD: {
              otherinfo: '',
              slides: [],
            },
            YTD: {
              otherinfo: '',
              slides: [],
            },
          },
        },
      ],
    },
  ],
};

export { GraphDataMock };
