<app-devisions-header [allDivisionsTabs] ="divisionsData" (currentDivisionEmitter)="setCurrentDivision($event)"></app-devisions-header>
<ng-container *ngIf="data$ | async as data">
  <app-stocks-panel></app-stocks-panel>
  <app-sales-section [verticalBarData]="data[0]"></app-sales-section>
  <app-io-graph [verticalBarData]="data[1]"></app-io-graph>
  <app-editba-section [verticalBarData]="data[2]"></app-editba-section>
  <app-cf-section [verticalBarData]="data[3]"></app-cf-section>
  <app-top-products-section [horizontalBarData]="data[4]"></app-top-products-section>
  <app-top-clients-section [horizontalBarData]="data[5]"></app-top-clients-section>
  <app-map-section [mapBarData]="data[6]"></app-map-section>
  <app-potash-avg-section [potashAvgChartData]="data[11]"></app-potash-avg-section>
  <app-data-table-section [dataTable]="data[7]"></app-data-table-section>
  <app-financial-kpi-section [halfDoughnutBarData]="data[8]"></app-financial-kpi-section>
  <app-oi-by-sbu-section [oiByBsuData]="data[9]"></app-oi-by-sbu-section>
  <app-icl-fcf-section [iclFcfData]="data[10]"></app-icl-fcf-section>
  <app-icl-capex-section [pieGraphData]="data[12]"></app-icl-capex-section>
  <app-go-to-top-button></app-go-to-top-button>
</ng-container>
