import * as echarts from "echarts";

export const minusBarColorEmphasis = new echarts.graphic.LinearGradient(
  0,
  0,
  0,
  1,
  [
    {
      offset: 0,
      color: "#DA4A4F",
    },
    {
      offset: 1,
      color: "#FF6F74",
    },
  ]
);

export const lyBarColorEmphasis = new echarts.graphic.LinearGradient(
  0,
  0,
  0,
  1,
  [
    {
      offset: 0,
      color: "rgba(209,220,221,0.1)",
    },
    {
      offset: 1,
      color: "rgba(121,154,157, 0.3)",
    },
  ]
);

export const lyBarColor = "#024266";

export const currentBarColorEmphasis = new echarts.graphic.LinearGradient(
  0,
  0,
  0,
  1,
  [
    {
      offset: 0,
      color: "#D1DCDD",
    },
    {
      offset: 1,
      color: "#799A9D",
    },
  ]
);

export const currentBarColor = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
  {
    offset: 0,
    color: "#006F9F",
  },
  {
    offset: 1,
    color: "#1A4F69",
  },
]);
