import { Component, Input, OnInit } from '@angular/core';
import { GraphData } from 'src/app/model/data.MODEL';

@Component({
  selector: 'app-financial-kpi-section',
  templateUrl: './financial-kpi-section.component.html',
  styleUrls: ['./financial-kpi-section.component.scss']
})
export class FinancialKpiSectionComponent implements OnInit {
  @Input() halfDoughnutBarData!: GraphData | any;
  public finacialKPIData : any;
  constructor() { }

  ngOnInit(): void {
    this.finacialKPIData = this.halfDoughnutBarData.slides[0].data;
  }

}
