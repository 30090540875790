import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from '../../../app/services/data.service';
import { EDivisions } from './divisions.model';

@Component({
  selector: 'app-devisions-header',
  templateUrl: './devisions-header.component.html',
  styleUrls: ['./devisions-header.component.scss'],
})
export class DevisionsHeaderComponent implements OnInit {
  public currentDivisionTab: number = EDivisions.ICL;
  @Input() allDivisionsTabs: string[];
  public stylesOfChoosenDivistion: { left: string; width: string };

  @Output() currentDivisionEmitter: EventEmitter<EDivisions> =
    new EventEmitter<EDivisions>();

  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
  }
  public setStylesForChoosenDivistion(clickEvent: any, index: number): void {
    if(this.dataService.dataIsLoading){
      return;
    }
    this.currentDivisionTab = index;
    this.stylesOfChoosenDivistion = {
      left: clickEvent.target.offsetLeft + 'px',
      width: clickEvent.target.offsetWidth + 'px',
    };
    //this.dataService.setDataByDivision(this.currentDivisionTab);
    this.currentDivisionEmitter.emit(this.currentDivisionTab);
  }

  divisionToName(div) {
    if(div === 'SF') return 'IAS';
    return div;
  }


}

