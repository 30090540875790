import { Injectable } from '@angular/core';
import { GraphTypes } from 'src/app/model/data.MODEL';
import * as echarts from 'echarts';
import {
  minusBarColorEmphasis,
  lyBarColorEmphasis,
  currentBarColor,
  lyBarColor,
  currentBarColorEmphasis,
} from './barColors.js';

import { from } from 'rxjs';

export const Months = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const qtd = [
  '',
  'Q1',
  'Q2',
  'Q3',
  'Q4',
  'Q5', //FIXME
  'Q6',
  'Q7',
  'Q8',
];

@Injectable({
  providedIn: 'root',
})
export class ConverterService {
  private maxSizeBarForHorizontalBar: number = 0;

  getCapexGraph(normalizedData, graphName) {
    const graphData = {
      totalBudgetForYear: normalizedData.slides[0].data.reduce(
        (acc, item) => (acc += item.budget),
        0
      ),
      totalActiveBudget: normalizedData.slides[0].data.reduce(
        (acc, item) => (acc += item.isOverMonth ? 0 : item.budget),
        0
      ),
      activeGraphData: normalizedData.slides[0].data.map((item) => ({
        value: item.budget,
        name: item.actual,
        item: { ...item, month: Months[item.month] },
        itemStyle: { opacity: item.isOverMonth ? 0 : 1 },
      })),
      inactiveGraphData: normalizedData.slides[0].data.map((item) => ({
        value: item.budget,
        name: item.actual,
        item: { ...item, month: Months[item.month] },
        itemStyle: { opacity: item.isOverMonth ? 1 : 0 },
      })),
    };
    return {
      options: {
        tooltip: {
          trigger: 'item',
          show: false,
        },
        legend: {
          show: false,
          top: '5%',
          left: 'center',
        },
        series: [
          {
            name: 'Capex',
            type: 'pie',
            radius: [70, 90],
            itemStyle: {
              borderColor: '#002949',
              borderWidth: 4,
              color: '#01D8EA',
            },
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [...graphData.activeGraphData],
          },
          {
            name: 'Inactive',
            type: 'pie',
            radius: [83, 90],
            itemStyle: {
              borderColor: '#002949',
              borderWidth: 4,
              color: '#2C3E50',
            },
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: false,
              },
            },
            labelLine: {
              show: false,
            },
            data: [...graphData.inactiveGraphData],
          },
        ],
      },
    };
  }

  getKPIGraph(normalizedData, graphName) {
    const objectiveQuote =
      (normalizedData.otherInfo.objectives.value / 100) * 10;
    const formulaQuote = (normalizedData.otherInfo.formula.value / 100) * 10;

    const objectivesOptions = {
      color: {
        colorStops: [
          {
            offset: 0,
            color: '#16657e',
          },
          {
            offset: 0.5,
            color: '#00deef',
          },
        ],
        x: 0,
        y: 0.5,
        x2: 0.8,
        y2: 0,
        type: 'linear',
        global: false,
      },
      series: [
        {
          itemStyle: {
            color: '#01233c',
          },
          startAngle: 180,
          endAngle: 360,
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              value: 10,
              name: 'data-a',
            },
            {
              value: 10,
              name: null,
              itemStyle: {
                opacity: 0,
              },
              tooltip: {
                show: false,
              },
            },
          ],
        },
        {
          startAngle: 180,
          endAngle: 360,
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              value: objectiveQuote < 10 ? objectiveQuote : 10,
              name: 'data-a',
            },
            {
              value: 10,
              name: null,
              itemStyle: {
                opacity: 0,
              },
              tooltip: {
                show: false,
              },
            },
          ],
        },
      ],
    };

    const formulaOptions = {
      color: {
        colorStops: [
          {
            offset: 0,
            color: '#16657e',
          },
          {
            offset: 0.5,
            color: '#00deef',
          },
        ],
        x: 0,
        y: 0.5,
        x2: 0.8,
        y2: 0,
        type: 'linear',
        global: false,
      },
      series: [
        {
          itemStyle: {
            color: '#01233c',
          },
          startAngle: 180,
          endAngle: 360,
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              value: 10,
              name: 'data-a',
            },
            {
              value: 10,
              name: null,
              itemStyle: {
                opacity: 0,
              },
              tooltip: {
                show: false,
              },
            },
          ],
        },
        {
          startAngle: 180,
          endAngle: 360,
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              value: formulaQuote < 10 ? formulaQuote : 10,
              name: 'data-a',
            },
            {
              value: 10,
              name: null,
              itemStyle: {
                opacity: 0,
              },
              tooltip: {
                show: false,
              },
            },
          ],
        },
      ],
    };
    normalizedData.otherInfo.objectives.options = objectivesOptions;
    normalizedData.otherInfo.formula.options = formulaOptions;
    return normalizedData;
  }

  getFullVerticalSalesGraph(normalizedData, graphName, noSlides = false) {
    const normalizeVerticaldGraph = {
      M: normalizedData.nav.M
        ? this.getVerticalGraphForSalesSlide(
            normalizedData.nav.M,
            Months,
            noSlides,
            graphName
          )
        : null,
      QTD: this.getVerticalGraphForSalesSlideQTD(
        normalizedData.nav.QTD,
        qtd,
        noSlides,
        graphName
      ),
      YTD: this.getVerticalGraphForSalesSlide(
        normalizedData.nav.YTD,
        Months,
        noSlides,
        graphName
      ),
    };
    return normalizeVerticaldGraph;
  }

  getFullVerticalGraph(normalizedData, graphName, noSlides = false) {
    const normalizeVerticaldGraph = {
      M: normalizedData.nav.M
        ? this.getVerticalGraphForSlide(
            normalizedData.nav.M,
            Months,
            noSlides,
            graphName
          )
        : null,
      QTD: this.getVerticalGraphForSlideQTD(
        normalizedData.nav.QTD,
        qtd,
        noSlides,
        graphName
      ),
      YTD: this.getVerticalGraphForSlide(
        normalizedData.nav.YTD,
        Months,
        noSlides,
        graphName
      ),
    };
    return normalizeVerticaldGraph;
  }

  getFullPPTable(normalizedData, graphName) {
    return this.getGraphDataPPTabe(normalizedData, graphName, graphName);
  }

  getFullHorizontalGraph(normalizedData, graphName) {
    const normalizeHorizontalGraph = {
      M: this.getHorizontalGraphForSlide(normalizedData.nav.M, Months),
      QTD: this.getHorizontalGraphForSlide(normalizedData.nav.QTD, qtd),
      YTD: this.getHorizontalGraphForSlide(normalizedData.nav.YTD, Months),
    };
    return normalizeHorizontalGraph;
  }

  getHorizontalGraphForSlide(normalizedData, barTextArray) {
    return {
      otherinfo: '',
      slides: [
        this.getGraphDataForHorizontalGraph(
          normalizedData.slides[0],
          0,
          barTextArray
        ),
        this.getGraphDataForHeatMap(normalizedData.slides[0], 0, barTextArray),
        // this.getGraphDataForHorizontalGraph(normalizedData.slides[1], 1, barTextArray),
        // this.getGraphDataForHorizontalGraph(normalizedData.slides[2], 2, barTextArray),
      ],
    };
  }

  preventValueNotZero(value: any) {
    if (value > 0) {
      return value;
    } else {
      return (Math.random() * 10).toFixed(2);
    }
  }

  getVerticalGraphForSalesSlide(
    normalizedData,
    barTextArray,
    noExtraSlides = false,
    chartName
  ) {
    if (noExtraSlides) {
      return {
        defaultValue: normalizedData['defaultValue'],
        otherinfo: '',
        slides: [
          this.getGraphDataForVerticalGraph(
            normalizedData.slides[0],
            0,
            barTextArray,
            chartName
          ),
        ],
      };
    }
    return {
      defaultValue: normalizedData['defaultValue'],
      otherinfo: '',
      slides: [
        this.getGraphDataForVerticalSlideWithCompare(
          normalizedData.slides[0],
          0,
          normalizedData.slides[1],
          barTextArray
        ),
        this.getGraphDataForVerticalSalesGraph(
          normalizedData.slides[1],
          1,
          barTextArray
        ),
        this.getGraphDataForVerticalSlideWithCompare(
          normalizedData.slides[2],
          2,
          normalizedData.slides[1],
          barTextArray
        ),
      ],
    };
  }

  getVerticalGraphForSlideQTD(
    normalizedData,
    barTextArray,
    noExtraSlides = false,
    chartName
  ) {
    if (noExtraSlides) {
      return {
        defaultValue: normalizedData['defaultValue'],
        otherinfo: '',
        slides: [
          this.getGraphDataForVerticalGraph(
            normalizedData.slides[0],
            0,
            barTextArray,
            chartName
          ),
        ],
      };
    }
    return {
      defaultValue: normalizedData['defaultValue'],
      otherinfo: '',
      slides: [
        this.getGraphDataForVerticalSlideWithCompareQTD(
          normalizedData.slides[0],
          0,
          normalizedData.slides[1],
          barTextArray,
          normalizedData['defaultValue'],
          chartName
        ),
        this.getGraphDataForVerticalGraph(
          normalizedData.slides[1],
          1,
          barTextArray,
          chartName
        ),
        this.getGraphDataForVerticalSlideWithCompareQTD(
          normalizedData.slides[2],
          2,
          normalizedData.slides[1],
          barTextArray,
          normalizedData['defaultValue'],
          chartName
        ),
      ],
    };
  }

  getVerticalGraphForSalesSlideQTD(
    normalizedData,
    barTextArray,
    noExtraSlides = false,
    chartName
  ) {
    if (noExtraSlides) {
      return {
        defaultValue: normalizedData['defaultValue'],
        otherinfo: '',
        slides: [
          this.getGraphDataForVerticalGraph(
            normalizedData.slides[0],
            0,
            barTextArray,
            chartName
          ),
        ],
      };
    }
    return {
      defaultValue: normalizedData['defaultValue'],
      otherinfo: '',
      slides: [
        this.getGraphDataForVerticalSlideWithCompareQTD(
          normalizedData.slides[0],
          0,
          normalizedData.slides[1],
          barTextArray,
          normalizedData['defaultValue']
        ),
        this.getGraphDataForVerticalSalesGraph(
          normalizedData.slides[1],
          1,
          barTextArray
        ),
        this.getGraphDataForVerticalSlideWithCompareQTD(
          normalizedData.slides[2],
          2,
          normalizedData.slides[1],
          barTextArray,
          normalizedData['defaultValue']
        ),
      ],
    };
  }

  getVerticalGraphForSlide(
    normalizedData,
    barTextArray,
    noExtraSlides = false,
    graphName
  ) {
    if (noExtraSlides) {
      return {
        defaultValue: normalizedData['defaultValue'],
        otherinfo: '',
        slides: [
          this.getGraphDataForVerticalGraph(
            normalizedData.slides[0],
            0,
            barTextArray,
            graphName
          ),
        ],
      };
    }
    return {
      defaultValue: normalizedData['defaultValue'],
      otherinfo: '',
      slides: [
        this.getGraphDataForVerticalSlideWithCompare(
          normalizedData.slides[0],
          0,
          normalizedData.slides[1],
          barTextArray
        ),
        this.getGraphDataForVerticalGraph(
          normalizedData.slides[1],
          1,
          barTextArray
        ),
        this.getGraphDataForVerticalSlideWithCompare(
          normalizedData.slides[2],
          2,
          normalizedData.slides[1],
          barTextArray
        ),
      ],
    };
  }

  generateItemBarDataForCompareGraph(item, compareItem) {
    if (compareItem.amount === null) {
      return {
        label: {
          color: '#768B95',
          rich: {
            title: {
              color: '#768B95',
            },
          },
        },
        emphasis: {
          label: {
            show: true,
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(209,220,221,0.1)',
              },
              {
                offset: 1,
                color: 'rgba(121,154,157, 0.3)',
              },
            ]),
          },
        },
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(209,220,221,0.1)',
              },
              {
                offset: 1,
                color: 'rgba(121,154,157, 0.3)',
              },
            ]),
          },
        },
      };
    }
    // if (item.amount < 0) {
    //   return {
    //     label: {
    //       color: '#FF6F74',
    //     },
    //     emphasis: {
    //       itemStyle: {
    //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //           {
    //             offset: 0,
    //             color: '#DA4A4F',
    //           },
    //           {
    //             offset: 1,
    //             color: '#FF6F74',
    //           },
    //         ]),
    //       },
    //     },
    //   };
    // }
    return {};
  }

  getGraphDataPPTabe(rawSlide, id, barText) {
    const slide = {
      id: id,
      title: rawSlide.title,
      data: {
        type: GraphTypes.BASETABLE,
        title: rawSlide.title,
        name: '',
        description: '',
      },
      options: {
        textStyle: {
          fontSize: 10,
          fontFamily: 'Demo Regular',
        },
        graphic: [
          {
            elements: [
              {
                id: 'small_circle',
                type: 'rect',
                shape: {
                  x: -10,
                  y: 170,
                  width: 1000,
                  height: 50,
                },
                style: {
                  fill: '#024266',
                  stroke: '#40697f',
                },
              },
            ],
          },
        ],
        xAxis: [
          {
            type: 'category',
            data: '',
            splitLine: {},
            axisLabel: {
              backgroundColor: '#024266',
            },
            axisLine: {
              lineStyle: {
                color: '#40697f',
              },
            },
          },
          {
            type: 'category',
            data: [],
            position: 'bottom',
            offset: 20,
            axisLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            position: 'right',
            axisLine: {
              lineStyle: {
                color: '#40697f',
              },
            },
            splitLine: {
              lineStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: '#002949',
                  },
                  {
                    offset: 0.5,
                    color: '#025579',
                  },
                  {
                    offset: 1,
                    color: '#002949',
                  },
                ]),
              },
            },
          },
        ],
        grid: {
          left: 30,
          top: 0,
          right: 50,
          bottom: 50,
        },
        series: [
          {
            label: {
              show: false,
              valueAnimation: true,
              position: 'top',
              fontSize: 12,
              formatter: ['{title|{c}}', '{change|{b}%}'].join('\n'),
              rich: {
                title: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  fontWeight: 'bold',
                  color: '#00bdde',
                  fontSize: 14,
                },
                change: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  color: '#00bdde',
                  fontSize: 11,
                },
              },
            },
            data: [],
            type: 'bar',
            barWidth: 20,
            emphasis: {
              label: {
                show: true,
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#01d9eb',
                  },
                  {
                    offset: 1,
                    color: '#004765',
                  },
                ]),
              },
            },
            itemStyle: {
              normal: {
                shadowBlur: 16,
                shadowColor: 'rgba(0,0,0,0.06)',
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#025579',
                  },
                  {
                    offset: 1,
                    color: '#004765',
                  },
                ]),
              },
            },
          },
        ],
      },
    };
    return slide;
  }

  getGraphDataForVerticalSlideWithCompare(
    rawSlide,
    id,
    comparedRawSlide,
    barText
  ) {
    const slide = {
      id: id,
      title: rawSlide.title,
      moreData:
        rawSlide && rawSlide.data[0] && rawSlide.data[0].moreData
          ? rawSlide.data.map((item) => item.moreData)
          : null,
      data: {
        type: GraphTypes.BASETABLE,
        title: rawSlide.title,
        name: '',
        description: '',
      },
      options: {
        textStyle: {
          fontSize: 10,
          fontFamily: 'Demo Regular',
        },
        tooltip: {
          show: true,
          trigger: 'item',
          position: [10000, 10000],
          axisPointer: {
            lineStyle: {
              opacity: 0,
            },
          },
        },
        graphic: [
          {
            elements: [
              {
                z: 50,
                id: 'small_circle',
                type: 'rect',
                shape: {
                  x: -10,
                  y: 170,
                  width: 1000,
                  height: 50,
                },
                style: {
                  fill: '#024266',
                  stroke: '#40697f',
                },
              },
            ],
          },
        ],
        xAxis: [
          {
            z: 55,
            type: 'category',
            data: rawSlide.data.map((item) => barText[item.month]), //bar names
            axisLabel: {
              backgroundColor: '#024266',
              color: '#768B95',
              opacity: 1,
            },
            axisLine: {
              show: false,
              lineStyle: {
                opacity: 0.4,
                color: '#40697f',
              },
            },
            axisTick: {
              // alignWithLabel : true,
              zlevel: 10,
              z: 55,
              interval: (index: number, value: string) => {
                let last = rawSlide.data.length;
                if (index !== 0 && index !== last) {
                  return true;
                }
              },
            },
          },
        ],
        yAxis: [
          {
            splitNumber: 3,
            type: 'value',
            position: 'right',
            axisLabel: {
              formatter: function (obj: string, index: number) {
                return +obj !== 0 && index <= 3 ? obj : null;
              },
            },
            axisLine: {
              lineStyle: {
                color: '#40697f',
              },
            },
            splitLine: {
              lineStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: '#002949',
                  },
                  {
                    offset: 0.5,
                    color: '#025579',
                  },
                  {
                    offset: 1,
                    color: '#002949',
                  },
                ]),
              },
            },
          },
        ],
        grid: {
          left: 30,
          top: 30,
          right: 50,
          bottom: 50,
        },
        series: [
          {
            label: {
              show: false,
              valueAnimation: true,
              position: 'top',
              fontSize: 14,
              padding: [0, 10, 0, 0],
              formatter: ['{title|{c}}'].join('\n'),
              color: '#fff',
              rich: {
                title: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  fontWeight: 'bold',

                  fontSize: 14,
                },
              },
            },

            // { value: 23, name: 0 },
            data: rawSlide.data.map((item, index) => ({
              value: item.amount,
              name: item.percent,
              ...this.generateItemBarDataForCompareGraph(
                item,
                comparedRawSlide.data[index]
              ),
            })),
            type: 'bar',
            barWidth: 13,
            barGap: 0,
            stack: 'x',
            emphasis: {
              label: {
                show: true,
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#fff',
                  },
                  {
                    offset: 1,
                    color: '#004765',
                  },
                ]),
              },
            },
            itemStyle: {
              normal: {
                // shadowBlur: 16,
                // shadowColor: 'rgba(255,255,255,0.4)',
                // shadowOffsetX: -2,
                // shadowOffsetY: 1,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#006e9c',
                  },
                  {
                    offset: 1,
                    color: '#025579',
                  },
                ]),

                // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //   {
                //     offset: 0,
                //     color: 'rgba(209,220,221,0.1)',
                //   },
                //   {
                //     offset: 1,
                //     color: 'rgba(121,154,157, 0.3)',
                //   },
                // ]),
              },
            },
          },
          {
            label: {
              show: false,
              valueAnimation: true,
              position: 'top',
              fontSize: 12,
              padding: [0, 0, 0, 10],
              formatter: ['{title|{c}}', '{change|{b}%}'].join('\n'),
              color: '#00bdde',
              rich: {
                title: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  fontWeight: 'bold',
                  fontSize: 14,
                },
                change: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  color: '#00bdde',
                  fontSize: 10,
                },
              },
            },
            data: comparedRawSlide.data.map((item, index) => ({
              value: item.amount,
              name: rawSlide.data[index].percent,
              label: item.amount < 0 && {
                offset: [item.amount.toString().length, 0],
                color: '#FF6F74',
              },
              emphasis: item.amount < 0 && {
                itemStyle: {
                  color: minusBarColorEmphasis,
                },
              },
            })),

            type: 'bar',
            barWidth: 13,
            emphasis: {
              label: {
                show: true,
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#01d9eb',
                  },
                  {
                    offset: 1,
                    color: '#004765',
                  },
                ]),
              },
            },
            itemStyle: {
              normal: {
                shadowBlur: 6,
                shadowColor: 'rgba(0,0,0,0.3)',
                shadowOffsetX: -2,
                shadowOffsetY: -1,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#025579',
                  },
                  {
                    offset: 1,
                    color: '#004765',
                  },
                ]),
              },
            },
          },
        ],
      },
    };
    return slide;
  }

  getGraphDataForVerticalSlideWithCompareQTD(
    rawSlide,
    id,
    comparedRawSlide,
    barText,
    defaultValue?,
    chartName?,
    isFCF?
  ) {
    const max = this.Max([
      rawSlide.data.map((x) => x.amount),
      rawSlide.data.map((x) => x.lyAmount),
      comparedRawSlide.data.map((x) => x.amount),
    ]);
    const delta = max * 0.2;
    const offset = 18;
    const slide = {
      id: id,
      title: rawSlide.title,
      moreData:
        rawSlide && rawSlide.data[0] && rawSlide.data[0].moreData
          ? rawSlide.data.map((item) => item.moreData)
          : null,
      data: {
        type: GraphTypes.BASETABLE,
        title: rawSlide.title,
        name: '',
        description: '',
      },
      options: {
        textStyle: {
          fontSize: 10,
          fontFamily: 'Demo Regular',
        },
        tooltip: {
          show: true,
          trigger: 'item',
          enterable: true,
          position: [10000, 10000],
          axisPointer: {
            lineStyle: {
              opacity: 0,
            },
          },
        },
        graphic: [
          {
            elements: [
              {
                z: 50,
                id: 'small_circle',
                type: 'rect',
                shape: {
                  x: -10,
                  y: 170,
                  width: 1000,
                  height: 50,
                },
                style: {
                  fill: '#024266',
                  stroke: '#40697f',
                },
              },
            ],
          },
        ],
        xAxis: [
          {
            z: 55,
            type: 'category',
            data: rawSlide.data.map((item) => barText[item.month]), //bar names
            axisLabel: {
              backgroundColor: 'transparent',
              color: '#768B95',
              opacity: 1,
            },
            axisLine: {
              show: false,
              lineStyle: {
                opacity: 0.4,
                color: '#40697f',
              },
            },
            axisTick: {
              // alignWithLabel : true,
              zlevel: 10,
              z: 55,
              interval: (index: number, value: string) => {
                let last = rawSlide.data.length;
                if (index !== 0 && index !== last) {
                  return true;
                }
              },
            },
          },
        ],
        yAxis: [
          {
            splitNumber: 3,
            type: 'value',
            position: 'right',
            axisLabel: {
              formatter: function (obj: string, index: number) {
                return +obj !== 0 ? obj : null;
              },
            },
            axisLine: {
              lineStyle: {
                color: '#40697f',
              },
            },
            splitLine: {
              lineStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: '#002949',
                  },
                  {
                    offset: 0.5,
                    color: '#025579',
                  },
                  {
                    offset: 1,
                    color: '#002949',
                  },
                ]),
              },
            },
          },
        ],
        grid: {
          left: 30,
          top: 40,
          right: 50,
          bottom: 50,
        },
        series: [
          {
            markArea: isFCF && {
              silent: 'true',
              itemStyle: {
                color: 'transparent',
              },
              data: [
                [
                  {
                    name: rawSlide.data[0]?.year,
                    xAxis: 0,
                  },
                  {
                    xAxis: 3,
                  },
                ],
              ],
              label: {
                fontSize: 12,
                position: 'bottom',
                color: '#768B95',
                offset: [0, 20],
              },
            },
            label: {
              show: false,
              valueAnimation: true,
              position: 'top',
              fontSize: 14,
              padding: [0, 10, 0, 0],
              formatter: ['{title|{c}}'].join('\n'),
              rich: {
                title: {
                  color: '#fff',
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  fontWeight: 'bold',
                  fontSize: 14,
                },
                titleGrey: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  fontWeight: 'bold',
                  color: '#768B95',
                  fontSize: 14,
                },
                titleRed: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  fontWeight: 'bold',
                  color: '#FF6F74',
                  fontSize: 14,
                },
              },
            },
            data: rawSlide.data.map((item, index) => {
              if (item.lyAmount < 0 && item.amount < 0) {
                if (item.amount < item.lyAmount) {
                  return {
                    value: item.lyAmount,
                    name: item.lyAmount,
                    label: {
                      formatter: [
                        `{titleGrey|${item.lyAmount}}`,
                        `{titleRed|${item.amount}}`,
                      ].join('\n'),
                    },
                    emphasis: {
                      itemStyle: {
                        color: lyBarColorEmphasis,
                      },
                    },
                    itemStyle: {
                      normal: {
                        color: lyBarColor,
                      },
                    },
                  };
                }
                return {
                  value: item.amount,
                  name: item.amount,
                  label: {
                    formatter: [
                      `{titleRed|${item.amount}}`,
                      `{titleGrey|${item.lyAmount}}`,
                    ].join('\n'),
                  },
                  emphasis: {
                    itemStyle: {
                      color: minusBarColorEmphasis,
                    },
                  },
                  itemStyle: {
                    normal: {
                      color: currentBarColor,
                    },
                  },
                  ...this.generateItemBarDataForCompareGraph(
                    item,
                    comparedRawSlide.data[index]
                  ),
                };
              }
              if (item.lyAmount > 0 && item.amount < 0) {
                return {
                  value: item.amount,
                  name: item.lyAmount,
                  label: {
                    formatter: [`{titleRed|${item.amount}}`].join('\n'),
                  },
                  emphasis: {
                    itemStyle: {
                      color: minusBarColorEmphasis,
                    },
                  },
                  itemStyle: {
                    normal: {
                      color: currentBarColor,
                    },
                  },
                  ...this.generateItemBarDataForCompareGraph(
                    item,
                    comparedRawSlide.data[index]
                  ),
                };
              }
              if (index == defaultValue - 1 && item.lyAmount === 0) {
                return {
                  value: item.amount,
                  label:
                    item.amount < 0
                      ? {
                          formatter: [
                            `{titleGrey|${item.lyAmount}}`,
                            `{titleRed|${item.amount}}`,
                          ].join('\n'),
                        }
                      : {
                          formatter: [
                            `{title|${item.amount}}`,
                            `{titleGrey|${item.lyAmount}}`,
                          ].join('\n'),
                        },
                  emphasis: item.amount < 0 && {
                    itemStyle: {
                      color: minusBarColorEmphasis,
                    },
                  },
                };
              }
              if (
                index == defaultValue - 1 &&
                item.lyAmount &&
                item.lyAmount < item.amount
              ) {
                return {
                  value: item.lyAmount,
                  name: item.percent,
                  label: {
                    color: 'rgba(0, 0, 0, 0)',
                    formatter: [''],
                  },
                  itemStyle: {
                    normal: {
                      color: lyBarColor,
                    },
                  },
                  emphasis: {
                    label: {
                      color: 'rgba(0, 0, 0, 0)',
                    },
                    itemStyle: {
                      color: '#436378',
                    },
                  },
                  ...this.generateItemBarDataForCompareGraph(
                    item,
                    comparedRawSlide.data[index]
                  ),
                };
              }
              const isPosBigger = this.isPosBiggerThanDelta(
                delta,
                item.lyAmount,
                item.amount
              );
              return {
                value: item.amount,
                name: item.percent ? item.percent : '',
                label: {
                  formatter: isPosBigger
                    ? ['']
                    : item.amount < 0
                    ? ['{titleRed|{c}}'].join('\n')
                    : ['{title|{c}}'].join('\n'),
                  color:
                    item.amount < 0
                      ? '#FF6F74'
                      : isPosBigger && 'rgba(0, 0, 0, 0)',
                },
                emphasis: {
                  label: {
                    color: isPosBigger && 'rgba(0, 0, 0, 0)',
                  },
                  itemStyle: item.amount < 0 && {
                    color: minusBarColorEmphasis,
                  },
                },
                ...this.generateItemBarDataForCompareGraph(
                  item,
                  comparedRawSlide.data[index]
                ),
              };
            }),
            type: 'bar',
            barWidth: 18,
            barGap: 0,
            stack: 'x',
            emphasis: {
              label: {
                show: true,
              },
              itemStyle: {
                color: currentBarColorEmphasis,
              },
            },
            itemStyle: {
              normal: {
                color: currentBarColor,
              },
            },
          },
          {
            label: {
              show: false,
              valueAnimation: true,
              position: 'top',
              fontSize: 12,
              padding: [0, 0, 0, 10],
              formatter: ['{title|{c}}', '{change|{b}%}'].join('\n'),
              rich: {
                title: {
                  fontFamily: 'Demo Regular',
                  color: '#00bdde',
                  align: 'center',
                  fontWeight: 'bold',
                  fontSize: 14,
                },
                titleMinus: {
                  fontFamily: 'Demo Regular',
                  color: '#FF6F74',
                  align: 'center',
                  fontWeight: 'bold',
                  fontSize: 14,
                },
                change: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  color: '#00bdde',
                  fontSize: 10,
                },
              },
            },
            data: comparedRawSlide.data.map((item, index) => ({
              value: item.amount,
              name: rawSlide.data[index].percent,
              label: {
                offset: item.amount < 0 && [item.amount.toString().length, 0],
                formatter:
                  item.amount < 0
                    ? ['{titleMinus|{c}}', '{change|{b}%}'].join('\n')
                    : rawSlide.data[index].percent
                    ? ['{title|{c}}', '{change|{b}%}'].join('\n')
                    : ['{title|{c}}'].join('\n'),
              },
              emphasis: item.amount < 0 && {
                itemStyle: {
                  color: minusBarColorEmphasis,
                },
              },
            })),
            type: 'bar',
            barWidth: 18,
            emphasis: {
              label: {
                show: true,
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#01d9eb',
                  },
                  {
                    offset: 1,
                    color: '#004765',
                  },
                ]),
              },
            },
            itemStyle: {
              normal: {
                shadowBlur: 6,
                shadowColor: 'rgba(0,0,0,0.3)',
                shadowOffsetX: -2,
                shadowOffsetY: -1,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#025579',
                  },
                  {
                    offset: 1,
                    color: '#004765',
                  },
                ]),
              },
            },
          },
          {
            label: {
              show: false,
              valueAnimation: true,
              position: 'top',
              fontSize: 14,
              padding: [0, 10, 0, 0],
              formatter: ['{title|{b}}'].join('\n'),
              rich: {
                title: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  fontWeight: 'bold',
                  color: '#768B95',
                  fontSize: 14,
                },
                titleSwitch: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  fontWeight: 'bold',
                  color: '#fff',
                  fontSize: 14,
                },
                titleRed: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  fontWeight: 'bold',
                  color: '#FF6F74',
                  fontSize: 14,
                },
              },
            },
            stack: 'x',
            data: rawSlide.data.map((item, index) => {
              if (item.lyAmount < 0 && item.amount < 0) {
                if (item.amount < item.lyAmount) {
                  return {
                    value:
                      comparedRawSlide.data[index].amount !== null
                        ? item.amount - item.lyAmount
                        : null,
                    name: item.amount,
                    label: {
                      color: 'rgba(0, 0, 0, 0)',
                      show: false,
                      formatter: [''],
                    },
                    emphasis: {
                      label: {
                        color: 'rgba(0, 0, 0, 0)',
                        show: false,
                        formatter: [''],
                      },
                      itemStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0,
                            color: '#DA4A4F',
                          },
                          {
                            offset: 1,
                            color: '#FF6F74',
                          },
                        ]),
                      },
                    },
                    itemStyle: {
                      normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0,
                            color: '#006F9F',
                          },
                          {
                            offset: 1,
                            color: '#1A4F69',
                          },
                        ]),
                      },
                    },
                  };
                }
                return {
                  value:
                    comparedRawSlide.data[index].amount !== null
                      ? item.lyAmount - item.amount
                      : null,
                  name: item.amount,
                  label: {
                    color: 'rgba(0, 0, 0, 0)',
                    show: false,
                    formatter: [''],
                  },
                  emphasis: {
                    itemStyle: {
                      color: lyBarColorEmphasis,
                    },
                  },
                  itemStyle: {
                    normal: {
                      color: lyBarColor,
                    },
                  },
                };
              }
              if (item.lyAmount > 0 && item.amount < 0) {
                return {
                  value:
                    comparedRawSlide.data[index].amount !== null
                      ? item.lyAmount
                      : null,
                  label: {
                    formatter: [`{title|${item.lyAmount}}`].join('\n'),
                    offset: this.getOffset(
                      offset,
                      delta,
                      item.lyAmount,
                      item.amount
                    ),
                  },
                  emphasis: {
                    itemStyle: {
                      color: lyBarColorEmphasis,
                    },
                  },
                  itemStyle: {
                    normal: {
                      color: lyBarColor,
                    },
                  },
                };
              }
              if (
                index == defaultValue - 1 &&
                item.lyAmount &&
                item.lyAmount < item.amount
              ) {
                return {
                  value:
                    comparedRawSlide.data[index].amount === null
                      ? null
                      : item.lyAmount < 0
                      ? item.amount
                      : item.amount - item.lyAmount,
                  name: item.amount,
                  label: {
                    formatter: [
                      '{titleSwitch|{b}}',
                      `{title|${item.lyAmount}}`,
                    ].join('\n'),
                  },
                  emphasis: {
                    itemStyle: {
                      color: currentBarColorEmphasis,
                    },
                  },
                  itemStyle: {
                    normal: {
                      color: currentBarColor,
                    },
                  },
                };
              }
              if (index == defaultValue - 1 && item.lyAmount == 0) {
                return {
                  value:
                    comparedRawSlide.data[index].amount !== null
                      ? item.lyAmount
                      : null,
                  label: {
                    color: 'rgba(0, 0, 0, 0)',
                    show: false,
                    formatter: [''],
                  },
                  emphasis: {
                    label: {
                      color: 'rgba(0, 0, 0, 0)',
                      show: false,
                      formatter: [''],
                    },
                  },
                };
              }
              const isPosBigger = this.isPosBiggerThanDelta(
                delta,
                item.lyAmount,
                item.amount
              );
              return {
                value:
                  comparedRawSlide.data[index].amount === null ||
                  index < defaultValue - 1 ||
                  !defaultValue
                    ? null
                    : item.amount == null || item.amount < 0
                    ? item.lyAmount
                    : item.lyAmount - item.amount,
                name: item.lyAmount,
                label: {
                  offset: this.getOffset(
                    isPosBigger ? 0 : offset,
                    delta,
                    item.lyAmount,
                    item.amount
                  ),
                  formatter: isPosBigger
                    ? [
                        `{title|${item.lyAmount}}`,
                        `{titleSwitch|${item.amount}}`,
                      ].join('\n')
                    : ['{title|{b}}'].join('\n'),
                },
              };
            }),
            type: 'bar',
            barWidth: 18,
            barGap: 0,

            emphasis: {
              label: {
                show: true,
              },
              itemStyle: {
                color: lyBarColorEmphasis,
              },
            },
            itemStyle: {
              normal: {
                color: lyBarColor,
              },
            },
          },
        ],
      },
    };
    return slide;
  }

  getYOffset(offset, delta, position) {
    const ratio = offset / (-1 * delta);
    return -1 * (ratio * position + offset);
  }

  isPosBiggerThanDelta(delta, lyAmount, amount) {
    if (!lyAmount) {
      return false;
    }
    const position = lyAmount - Math.abs(amount);
    return position * 2 < delta;
  }

  getOffset(offset, delta, lyItem, item) {
    if (lyItem == undefined) {
      return;
    }
    if (item < 0 && lyItem > 0) {
      lyItem += Math.abs(item);
      if (lyItem - item < 0 || lyItem - Math.abs(item) > delta) {
        return [0, 0];
      }
      const position = lyItem - Math.abs(item) - 10;
      return [0, this.getYOffset(offset, delta, position)];
    }
    if (lyItem - item < 0 || lyItem - item > delta) {
      return [0, 0];
    }
    const position = lyItem - Math.abs(item);
    return [0, this.getYOffset(offset, delta, position)];
  }

  Max(arrays) {
    let arr = [];
    arrays.forEach((array) => {
      arr = arr.concat(array);
    });
    const max = Math.max(...arr.filter((item) => item !== undefined));
    return max;
  }

  shortenlabelByBarWidth(item, rawSlide): string {
    const arr = rawSlide.data.map((item) => +item.quantity);
    const windowScreen = window.innerWidth;
    this.maxSizeBarForHorizontalBar = Math.max(...arr);

    const widthRatioBar =
      (+item.quantity / this.maxSizeBarForHorizontalBar) * 100;

    const numberToCut =
      windowScreen * (widthRatioBar / 100) - item.customer_name.length * 5;
    item.customer_name =
      numberToCut > 0
        ? item.customer_name
        : item.customer_name.slice(
            0,
            item.customer_name.length + Math.floor(numberToCut) > 0
              ? item.customer_name.length + Math.floor(numberToCut)
              : 0
          ) + '...';
    return item.customer_name;
  }

  getGraphDataForHeatMap(rawSlide, id, barText) {
    const slide = {
      textStyle: {
        fontSize: 14,
        fontFamily: 'Demo Regular',
      },
      xAxis: [
        {
          show: false,
          type: 'value',
          boundaryGap: [0, 0.2],
          axisLine: {
            lineStyle: {
              color: '#40697f80',
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'category',
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
      ],
      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      series: [
        {
          itemStyle: {
            borderColor: '#002949',
            borderWidth: 2,
          },
          breadcrumb: {
            show: false,
          },
          roam: false,
          nodeClick: false,
          type: 'treemap',
          label: {
            color: '#ffffff',
            fontSize: 16,
          },
          width: '100%',
          height: '100%',
          data: rawSlide.data.map((item) => ({
            name: item.customer_name,
            value: item.quantity,
            itemStyle: {
              color: '#015578',
              borderColor: '#002949',
            },
          })),
        },
      ],
    };
    return {
      id: id,
      title: rawSlide.title,
      options: slide,
    };
  }

  getGraphDataForHorizontalGraph(rawSlide, id, barText) {
    const max = rawSlide.data.reduce((acc, item) => {
      if (item.quantity > acc) return item.quantity;
      else return acc;
    }, 0);
    return {
      title: 'horizontal bar',
      data: rawSlide.data.map((item) => {
        return { ...item, width: (item.quantity / max) * 100 };
      }),
    };
  }

  getGraphDataForVerticalGraph(rawSlide, id, barText, chartName?) {
    const yearData = [];
    if (rawSlide.data.length && rawSlide.data[0].year) {
      let currentYear = rawSlide.data[0].year;
      let startIndex = 0;
      for (let i = 1; i < rawSlide.data.length; i++) {
        if (rawSlide.data[i].year !== currentYear) {
          yearData.push([
            {
              name: currentYear,
              xAxis: startIndex,
            },
            {
              xAxis: i - 1,
            },
          ]);
          currentYear = rawSlide.data[i].year;
          startIndex = i;
        }
      }
      yearData.push([
        {
          name: currentYear,
          xAxis: startIndex,
        },
        {
          xAxis: rawSlide.data.length - 1,
        },
      ]);
    }
    const slide = {
      id: id,
      moreData:
        rawSlide && rawSlide.data[0] && rawSlide.data[0].moreData
          ? rawSlide.data.map((item) => item.moreData)
          : null,
      title: rawSlide.title,
      data: {
        type: GraphTypes.BASETABLE,
        title: rawSlide.title,
        name: '',
        description: '',
      },
      options: {
        textStyle: {
          fontSize: 10,
          fontFamily: 'Demo Regular',
        },
        graphic: [
          {
            elements:
              chartName !== 'CF'
                ? [
                    {
                      id: 'small_circle',
                      type: 'rect',
                      z: -10,
                      shape: {
                        x: -10,
                        y: 170,
                        width: 1000,
                        height: 50,
                      },
                      style: {
                        fill: '#024266',
                        stroke: '#40697f',
                      },
                    },
                  ]
                : [
                    {
                      id: 'small_circle',
                      type: 'rect',
                      z: -10,
                      shape: {
                        x: -10,
                        y: 170,
                        width: 1000,
                        height: 50,
                      },
                      style: {
                        fill: 'transparent',
                        stroke: '#40697f',
                      },
                    },
                  ],
          },
        ],
        xAxis: [
          {
            z: 55,
            type: 'category',
            data: rawSlide.data.map((item) => barText[item.month]),
            splitLine: {},
            axisLabel: {
              backgroundColor: 'transparent',
              color: '#768B95',
              opacity: 1,
            },
            axisLine: {
              // show: false,
              lineStyle: {
                color: '#40697f',
              },
            },
            axisTick: {
              z: 55,
              showLastLabel: false,
              interval: (index: number, value: string) => {
                let last = rawSlide.data.length;
                if (index == 0) {
                  return false;
                }
                return true;
              },
              // alignWithLabel : true
            },
          },
        ],
        yAxis: [
          {
            splitNumber: 3,
            type: 'value',
            position: 'right',
            axisLabel: {
              formatter: function (obj: string, index: number) {
                return +obj !== 0 ? obj : null;
              },
              showMinLabel: false,
            },
            axisLine: {
              lineStyle: {
                color: '#40697f',
              },
            },
            // minInterval: 30,
            splitLine: {
              lineStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: '#002949',
                  },
                  {
                    offset: 0.5,
                    color: '#025579',
                  },
                  {
                    offset: 1,
                    color: '#002949',
                  },
                ]),
              },
            },
          },
        ],
        grid: {
          left: 30,
          top: 30,
          right: 50,
          bottom: 50,
        },
        series: [
          {
            label: {
              show: false,
              valueAnimation: true,
              position: 'top',
              fontSize: 12,
              formatter:
                chartName !== 'CF'
                  ? ['{title|{c}}', '{change|{b}%}'].join('\n')
                  : ['{title|{c}}'].join('\n'),
              rich: {
                title: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  fontWeight: 'bold',
                  color: '#00bdde',
                  fontSize: 14,
                },
                titleMinus: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  fontWeight: 'bold',
                  color: '#FF6F74',
                  fontSize: 14,
                },
                change: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  color: '#00bdde',
                  fontSize: 10,
                },
              },
            },
            markArea: {
              silent: 'true',
              itemStyle: {
                color: 'transparent',
              },
              data: yearData,
              label: {
                fontSize: 12,
                position: 'bottom',
                color: '#768B95',
                offset: [0, 20],
              },
            },
            data: rawSlide.data.map((item, index) => ({
              value: item.amount,
              name: item.percent,
              dataIndex: index,
              label: {
                formatter:
                  item.amount < 0 && item.percent
                    ? ['{titleMinus|{c}}', '{change|{b}%}'].join('\n')
                    : item.amount >= 0 && item.percent
                    ? ['{title|{c}}', '{change|{b}%}'].join('\n')
                    : item.amount < 0 && !item.percent
                    ? ['{titleMinus|{c}}'].join('\n')
                    : ['{title|{c}}'].join('\n'),
              },
              emphasis: item.amount < 0 && {
                itemStyle: {
                  color: minusBarColorEmphasis,
                },
              },
            })),
            type: 'bar',
            barWidth: 20,
            emphasis: {
              label: {
                show: true,
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#01d9eb',
                  },
                  {
                    offset: 1,
                    color: '#004765',
                  },
                ]),
              },
            },
            itemStyle: {
              normal: {
                shadowBlur: 16,
                shadowColor: 'rgba(0,0,0,0.06)',
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#025579',
                  },
                  {
                    offset: 1,
                    color: '#004765',
                  },
                ]),
              },
            },
          },
        ],
      },
    };
    return slide;
  }

  getGraphDataForVerticalSalesGraph(rawSlide, id, barText) {
    const yearData = [];

    if (rawSlide.data.length && rawSlide.data[0].year) {
      let currentYear = rawSlide.data[0].year;
      let startIndex = 0;
      for (let i = 1; i < rawSlide.data.length; i++) {
        if (rawSlide.data[i].year !== currentYear) {
          yearData.push([
            {
              name: currentYear,
              xAxis: startIndex,
            },
            {
              xAxis: i - 1,
            },
          ]);
          currentYear = rawSlide.data[i].year;
          startIndex = i;
        }
      }
      yearData.push([
        {
          name: currentYear,
          xAxis: startIndex,
        },
        {
          xAxis: rawSlide.data.length - 1,
        },
      ]);
    }

    const slide = {
      id: id,
      moreData:
        rawSlide && rawSlide.data[0] && rawSlide.data[0].moreData
          ? rawSlide.data.map((item) => item.moreData)
          : null,
      title: rawSlide.title,
      data: {
        type: GraphTypes.BASETABLE,
        title: rawSlide.title,
        name: '',
        description: '',
      },
      options: {
        textStyle: {
          fontSize: 10,
          fontFamily: 'Demo Regular',
        },
        graphic: [
          {
            elements: [
              {
                id: 'small_circle',
                type: 'rect',
                shape: {
                  x: -10,
                  y: 170,
                  width: 1000,
                  height: 50,
                },
                style: {
                  fill: '#024266',
                  stroke: '#40697f',
                },
              },
            ],
          },
        ],
        xAxis: [
          {
            type: 'category',
            data: rawSlide.data.map((item) => barText[item.month]),
            splitLine: {},
            axisLabel: {
              backgroundColor: '#024266',
              color: '#768B95',
              opacity: 1,
            },
            axisLine: {
              lineStyle: {
                color: '#40697f',
              },
            },
          },
          {
            type: 'category',
            data: [],
            position: 'bottom',
            offset: 20,
            axisLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            splitNumber: 3,
            type: 'value',
            position: 'right',
            axisLabel: {
              formatter: function (obj: string, index: number) {
                return +obj !== 0 ? obj : null;
              },
            },
            axisLine: {
              lineStyle: {
                color: '#40697f',
              },
            },
            splitLine: {
              lineStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: '#002949',
                  },
                  {
                    offset: 0.5,
                    color: '#025579',
                  },
                  {
                    offset: 1,
                    color: '#002949',
                  },
                ]),
              },
            },
          },
        ],
        grid: {
          left: 30,
          top: 30,
          right: 50,
          bottom: 50,
        },
        series: [
          {
            label: {
              show: false,
              valueAnimation: true,
              position: 'top',
              fontSize: 12,
              formatter: ['{title|{c}}'].join('\n'),
              color: '#00bdde',
              rich: {
                title: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  fontWeight: 'bold',
                  fontSize: 14,
                },
                change: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  color: '#00bdde',
                  fontSize: 10,
                },
              },
            },
            markArea: {
              silent: 'true',
              itemStyle: {
                color: 'transparent',
              },
              data: yearData,
              label: {
                fontSize: 12,
                position: 'bottom',
                color: '#768B95',
                offset: [0, 20],
              },
            },
            data: rawSlide.data.map((item) => ({
              value: item.amount,
              name: 0,
              label: item.amount < 0 && {
                color: '#FF6F74',
              },
              emphasis: item.amount < 0 && {
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#DA4A4F',
                    },
                    {
                      offset: 1,
                      color: '#FF6F74',
                    },
                  ]),
                },
              },
            })),
            type: 'bar',
            barWidth: 20,
            emphasis: {
              label: {
                show: true,
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#01d9eb',
                  },
                  {
                    offset: 1,
                    color: '#004765',
                  },
                ]),
              },
            },
            itemStyle: {
              normal: {
                shadowBlur: 16,
                shadowColor: 'rgba(0,0,0,0.06)',
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#025579',
                  },
                  {
                    offset: 1,
                    color: '#004765',
                  },
                ]),
              },
            },
          },
        ],
      },
    };
    return slide;
  }

  getGraphDataForPotashAvgGraph(
    normalizedData,
    barTextArray,
    noSlides = false
  ) {
    const normalizeVerticaldGraph = {
      M: this.getVerticalGraphForSlidePA(normalizedData.nav.M, Months, true),
      QTD: this.getVerticalGraphForSlidePA(normalizedData.nav.QTD, qtd, true),
      YTD: this.getVerticalGraphForSlidePA(
        normalizedData.nav.YTD,
        Months,
        true
      ),
    };
    return normalizeVerticaldGraph;
  }

  getVerticalGraphForSlidePA(
    normalizedData,
    barTextArray,
    noExtraSlides = false
  ) {
    if (noExtraSlides) {
      return {
        otherinfo: '',
        slides: [
          this.getVerticalGraphForPA(normalizedData.slides[0], 0, barTextArray),
        ],
      };
    }
    return {
      otherinfo: '',
      slides: [
        this.getVerticalGraphForPA(normalizedData.slides[1], 1, barTextArray),
        this.getVerticalGraphForPA(normalizedData.slides[1], 1, barTextArray),
        this.getVerticalGraphForPA(normalizedData.slides[1], 1, barTextArray),
      ],
    };
  }

  getVerticalGraphForPA(rawSlide, id, barText, comparedRawSlide?) {
    const yearData = [];

    if (rawSlide.data.length && rawSlide.data[0].year) {
      let currentYear = rawSlide.data[0].year;
      let startIndex = 0;
      for (let i = 1; i < rawSlide.data.length; i++) {
        if (rawSlide.data[i].year !== currentYear) {
          yearData.push([
            {
              name: currentYear,
              xAxis: startIndex,
            },
            {
              xAxis: i - 1,
            },
          ]);
          currentYear = rawSlide.data[i].year;
          startIndex = i;
        }
      }
      yearData.push([
        {
          name: currentYear,
          xAxis: startIndex,
        },
        {
          xAxis: rawSlide.data.length - 1,
        },
      ]);
    }

    const slide = {
      id: 0,
      title: 'slide 1',
      data: {
        type: GraphTypes.BASETABLE,
        title: 'graphdata 1',
        name: 'name here 1',
        description: 'description here 1',
      },
      options: {
        tooltip: {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          padding: 0,
          left: 0,
          extraCssText: 'box-shadow: 0 0 0 rgba(0, 0, 0, 0.0);',
          show: true,
          trigger: 'axis',
          formatter:
            '<div style="color:#4E738B; height: 20px; font-size: 11px">{a0} &nbsp; &nbsp; {a1} &nbsp; &nbsp; {a2}</div><span style="color:#00BDDE; font-weight: bold; font-size:15px">{c0}</span>&nbsp;<span style="color: #004A6A50">|</span>&nbsp;<span style="font-size:15px; font-family: Demo Light">{c1}</span>&nbsp;<span style="color: #004A6A50">|</span>&nbsp;<span style="font-size:15px; font-family: Demo Light">{c2}</span>',
          position: function (point, params, dom, rect, size) {
            const rightSide = size.viewSize[0] - size.contentSize[0];
            const xPos = point[0] - size.contentSize[0] / 2;
            if (xPos > rightSide) {
              return [rightSide, '-8%'];
            }
            if (xPos < 0) {
              return [5, '-8%'];
            }
            return [xPos, '-8%'];
          },
          textStyle: {
            fontSize: 14,
            color: '#fff',
            fontFamily: 'Demo Regular',
          },
          axisPointer: {
            lineStyle: {
              opacity: 0,
            },
          },
        },
        textStyle: {
          fontSize: 10,
          fontFamily: 'Demo Regular',
        },
        graphic: [
          {
            elements: [
              {
                id: 'small_circle',
                type: 'rect',
                shape: {
                  x: -10,
                  y: 170,
                  width: 1000,
                  height: 50,
                },
                style: {
                  fill: '#024266',
                  stroke: '#40697f',
                },
              },
            ],
          },
        ],
        xAxis: [
          {
            // triggerEvent: true,
            type: 'category',
            data: rawSlide.data.map((item) => barText[item.month]),
            splitLine: {},
            axisLabel: {
              backgroundColor: '#024266',
              color: '#768B95',
              opacity: 1,
            },
            axisLine: {
              lineStyle: {
                color: '#40697f80',
              },
            },
          },
        ],
        yAxis: [
          {
            splitNumber: 3,
            type: 'value',
            position: 'right',
            axisLabel: {
              formatter: function (obj: string, index: number) {
                return +obj !== 0 ? obj : null;
              },
            },
            axisLine: {
              lineStyle: {
                color: '#40697f80',
              },
            },
            splitLine: {
              lineStyle: {
                color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                  {
                    offset: 0,
                    color: '#002949',
                  },
                  {
                    offset: 0.5,
                    color: '#025579',
                  },
                  {
                    offset: 1,
                    color: '#002949',
                  },
                ]),
              },
            },
          },
        ],
        grid: {
          left: 30,
          top: 20,
          right: 50,
          bottom: 50,
        },
        series: [
          {
            name: 'KMT',
            label: {
              show: false,
              valueAnimation: true,
              position: 'top',
              fontSize: 12,
              formatter: ['{title|{c}}', '{change|{b}%}'].join('\n'),
              rich: {
                title: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  fontWeight: 'bold',
                  color: '#00bdde',
                  fontSize: 14,
                },
                change: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  color: '#00bdde',
                  fontSize: 10,
                },
              },
            },
            markArea: {
              silent: 'true',
              itemStyle: {
                color: 'transparent',
              },
              data: yearData,
              label: {
                fontSize: 12,
                position: 'bottom',
                color: '#768B95',
                offset: [0, 20],
              },
            },
            data: rawSlide.data.map((item) => item.KMT),
            type: 'bar',
            barWidth: 20,
            emphasis: {
              label: {
                show: false,
              },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#01d9eb',
                  },
                  {
                    offset: 1,
                    color: '#004765',
                  },
                ]),
              },
            },
            itemStyle: {
              normal: {
                shadowBlur: 16,
                shadowColor: 'rgba(0,0,0,0.06)',
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#025579',
                  },
                  {
                    offset: 1,
                    color: '#004765',
                  },
                ]),
              },
            },
          },
          {
            name: 'FOB',
            symbolSize: 5,
            symbol: 'circle',
            type: 'line',
            barWidth: 1,
            label: {
              show: false,
              valueAnimation: true,
              position: 'top',
              fontSize: 12,
              formatter: ['{title|{c}}', '{change|{b}%}'].join('\n'),
              rich: {
                title: {
                  fontFamily: 'Demo Regular',
                  align: 'right',
                  fontWeight: 'bold',
                  color: '#00bdde',
                  fontSize: 14,
                },
                change: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  color: '#00bdde',
                  fontSize: 10,
                },
              },
            },
            data: rawSlide.data.map((item) => item.FOB),
            emphasis: {
              label: {
                show: false,
              },
              itemStyle: {
                color: '#D1DCDD',
              },
            },
            itemStyle: {
              normal: {
                color: '#002949',
                borderColor: '#00567B',
                borderWidth: 2,
                shadowBlur: 16,
                shadowColor: 'rgba(0,0,0,0.06)',
                shadowOffsetX: 2,
                shadowOffsetY: 2,
              },
            },
            lineStyle: {
              color: {
                colorStops: [
                  {
                    offset: 0,
                    color: '#02557980',
                  },
                  {
                    offset: 1,
                    color: '#004765',
                  },
                ],
              },
            },
          },
          {
            name: 'CIF',
            symbolSize: 5,
            symbol: 'circle',
            type: 'line',
            barWidth: 1,
            label: {
              show: false,
              valueAnimation: true,
              position: 'top',
              fontSize: 12,
              formatter: ['{title|{c}}', '{change|{b}%}'].join('\n'),
              rich: {
                title: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  fontWeight: 'bold',
                  color: '#00bdde',
                  fontSize: 14,
                },
                change: {
                  fontFamily: 'Demo Regular',
                  align: 'center',
                  color: '#00bdde',
                  fontSize: 10,
                },
              },
            },
            data: rawSlide.data.map((item) => item.CIF),
            emphasis: {
              label: {
                show: false,
              },
              itemStyle: {
                color: '#D1DCDD',
              },
            },
            itemStyle: {
              normal: {
                color: '#002949',
                borderColor: '#00567B',
                borderWidth: 2,
                shadowBlur: 16,
                shadowColor: 'rgba(0,0,0,0.06)',
                shadowOffsetX: 2,
                shadowOffsetY: 2,
              },
            },
            lineStyle: {
              color: {
                colorStops: [
                  {
                    offset: 0,
                    color: '#02557980',
                  },
                  {
                    offset: 1,
                    color: '#004765',
                  },
                ],
              },
            },
          },
        ],
      },
    };
    return slide;
  }
}
