<app-section-layout [itemTemplate]="dataTableEl" [dataForChart]="dataTable"></app-section-layout>
<ng-template #dataTableEl let-data>
    <div class="table-data-container">
        <table class="table-wrap">
            <thead>
                <tr>
                    <th style="width: 106px">Region</th>
                    <th>{{1 | forecast : data.data[0].forcast}}</th>
                    <th>{{2 | forecast : data.data[0].forcast}}</th>
                    <th>{{3 | forecast : data.data[0].forcast}}</th>
                    <th>{{4 | forecast : data.data[0].forcast}}</th>

                </tr>
            </thead>

            <tbody>
                <ng-container *ngFor="let record of data.data; let index = index">
                    <tr>
                        <td colspan="5">
                            <table class="table-inner">
                                <thead>
                                    <th><button (click)="getMoreData(index)"><img [src]="index === currentIndex ? './assets/arrow-bottom.PNG' : './assets/arrow-left.PNG'" alt="arrow icon" /></button>
                                    <span>{{record.region ? record.region : 'undefined'}}</span>
                                    </th>
                                    <td [style.background-color]="eVolumeFun(record['Q1A'].value)">{{record['Q1A'].value | number: '1.0-0'}}</td>
                                    <td [style.background-color]="eVolumeFun(record['Q2A'].value)">{{record['Q2A'].value | number: '1.0-0'}}</td>
                                    <td [style.background-color]="eVolumeFun(record['Q3A'].value)">{{record['Q3A'].value | number: '1.0-0'}}</td>
                                    <td [style.background-color]="eVolumeFun(record['Q4A'].value)">{{record['Q4A'].value | number: '1.0-0'}}</td>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="index === currentIndex">
                                    <tr class="more-data-container" *ngFor="let recordMore of record['moreData']">
                                        <th class="title">{{recordMore.name}}</th>
                                        <td>{{recordMore['Q1A'] | number: '1.0-0'}}</td>
                                        <td>{{recordMore['Q2A'] | number: '1.0-0'}}</td>
                                        <td>{{recordMore['Q3A'] | number: '1.0-0'}}</td>
                                        <td>{{recordMore['Q4A'] | number: '1.0-0'}}</td>
                                    </tr>
                                    </ng-container>

                                </tbody>

                            </table>
                        </td>

                </ng-container>
            </tbody>
        </table>
    </div>
</ng-template>
