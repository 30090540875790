<app-section-layout
  [itemTemplate]="salesGraph"
  [dataForChart]="data"
  (dataChanged)="onDataChanged($event)"
  (onAfterChange)="onAfterChange($event)"
  [maxIndexes]="maxIndexes"
  [currentIndexes]="currentIndexes"
  (maxIndexesChange)="onChangeMaxIndexes($event)"
></app-section-layout>

<ng-template #salesGraph let-data let-moreData="moreData" let-id="id">
  <div
    echarts
    [options]="data.options"
    class="chart-container {{ chartId }}"
    (chartInit)="onChartInit($event)"
  ></div>
  <ul class="list-of-product-data-container" *ngIf="moreData">
    <li *ngFor="let product of moreData; let last = last">
      <span class="title">{{ product.name }}</span>
      <span class="value">{{ negativeRounder(product.value) }}</span>
      <span class="last-year">{{
        negativeRounder(product.lastYearValue)
      }}</span>
    </li>
  </ul>
</ng-template>
