<app-section-layout [itemTemplate]="salesGraph" [dataForChart]="data"></app-section-layout>
<ng-template #salesGraph let-data let-moreData="moreData">
        <div class="pie-top-text">{{selectedItem ? selectedItem.month + ' YTD' : '&nbsp;'}}</div>
        <div class="pie-middle-text">
            <span class="actual">{{selectedItem ? selectedItem.actual : '&nbsp;'}}</span>
            <span class="text">{{selectedItem ? 'Actual' : '&nbsp;'}}</span>
        </div>
        <div echarts [id]="chartId" [options]="data.options" class="chart-container" (chartInit)="onChartInit($event)"></div>
        <!-- <div class="pie-bottom-text">63 (15%) Under Budget</div> -->
        <div class="pie-bottom-text">{{selectedItem ? '' + selectedItem.budgetDeltaNumber + ' (' + selectedItem.budgetDeltaPercent + '%) ' + (selectedItem.isOverBudget ? 'Above' : 'Under') + ' Budget'  : '&nbsp;'}}</div>
</ng-template>
