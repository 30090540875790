import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { GraphData } from 'src/app/model/data.MODEL';
import * as echarts from 'echarts';

@Component({
  selector: 'app-horizontal-bar-chart-rtba',
  templateUrl: './horizontal-bar-chart-rtba.component.html',
  styleUrls: ['./horizontal-bar-chart-rtba.component.scss'],
})
export class HorizontalBarChartRtbaComponent implements OnInit ,AfterViewInit {
  @Input() data!: GraphData;

  @Input() chartId: string = '';
  private canvasElement: any;
  private canvasElementEChart: any;
  constructor() {}

  public ngOnInit(): void {}

  public ngAfterViewInit(): void{
    setTimeout(() => {
      this.canvasElement = document.getElementById(this.chartId);
        this.eventsOnBarChart();
        this.canvasElementEChart = echarts.init(this.canvasElement);
      });
  }

  public trackByFn(index, item) {
    return index;
  }

  public selectedItem = 0;

  public barClick(index) {
    this.selectedItem = index;
    // console.log('clicked' + this.selectedItem);
  }

  public eventsOnBarChart(): void {
    var myChart = echarts.init(this.canvasElement);
    var tempDiv = document.createElement('div');
    
    tempDiv.classList.add('tooltip-info-horizontal-chart');
    myChart.dispatchAction({ type: 'highlight', dataIndex: 0 })
    this.canvasElement.insertAdjacentElement('afterend', tempDiv);
    tempDiv.style.display = 'none';
    myChart.on('click', 'series', function (params) {
      if (params.dataIndex !== 0) {
        myChart.dispatchAction({ type: 'downplay', dataIndex: 0 })
      }
      tempDiv.style.top = params.event.target['shape'].y + 30 + 'px';
      tempDiv.innerHTML = `<p><span>KMT</span>${params.data['KMT']}</p> <p><span>SMT</span>${params.data['SMT']}</p>`;
      tempDiv.style.display = 'block';
      myChart.dispatchAction({
        type: 'mouseup',
        seriesIndex: 0,
        dataIndex: params.dataIndex,
      });
      
      const option = myChart.getOption();
      myChart.setOption({animation: false});
      
      option.series[0].data = option.series[0].data.filter(item=>item!==0);
      option.series[0].data.splice(params.dataIndex + 1 ,0, 0); 
      
      const newData = option.series[0].data;
        myChart.setOption({
          series: [{
            data: newData
          }]
        });
        
        myChart.setOption({animation: true});
    });

    myChart.on('mouseout', function () {
      tempDiv.style.display = 'none';
    });
  }
}
