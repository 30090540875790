import { Component, Input, OnInit } from '@angular/core';
import { EVolume } from '../../../app/model/data.MODEL';

@Component({
  selector: 'app-data-table-section',
  templateUrl: './data-table-section.component.html',
  styleUrls: ['./data-table-section.component.scss'],
})
export class DataTableSectionComponent implements OnInit {
  @Input() dataTable: any;
  public currentIndex: number;
  public eVolume: any;
  constructor() { }

  ngOnInit(): void {
    this.eVolume = EVolume;
  }



  public getMoreData(index: number): void {
    if (this.currentIndex === index) {
      this.currentIndex = null
    }
    else {
      this.currentIndex = index;
    }
  }
  public eVolumeFun(value : number) : string{
    //const valueAsNumber : number = +(value);
    //const volumeByColor = Math.round(valueAsNumber);
    const ratio = (value - this.dataTable.min)/(this.dataTable.delta);
    // console.log(ratio);
    return `rgb(${218 - Math.round(ratio * 109)},${222 - Math.round(ratio * 38)},${146 - Math.round(ratio * 19)})`;
  }
}
