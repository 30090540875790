import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, zip, of } from 'rxjs';
// import { pipe, zip } from 'rxjs/operators';
import { GraphDataMock } from 'src/assets/graph.mock';
import {
  GraphData,
  IDivision,
  // IStocksSymbols,
} from '../model/data.model';
import { AdalLoginService } from './adal-login.service';
import { ConverterService } from './data-converter/converter-service';
import normalizeRawJson from './data-converter/converter.js';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private dataSubject: BehaviorSubject<GraphData[]> = new BehaviorSubject<
    GraphData[]
  >(null);
  public data$: Observable<GraphData[]> = this.dataSubject.asObservable();
  public divisionsData: string[];
  private currentDivision: string;
  private initialValueForDivisionsData = 0;
  public dataIsLoading = false;
  // private verticalBarData: GraphData[];
  private verticalBarData: any[];
  //@ts-ignore
  private rawData: any;
  private url: any;

  constructor(
    private adalLoginService: AdalLoginService,
    private dataConverter: ConverterService,
    private httpClient: HttpClient,
    private route: ActivatedRoute
  ) {
    this.divisionsData = GraphDataMock.divisions.map(
      (item: IDivision) => item.division
    );

    this.divisionsData = GraphDataMock.divisions.map(
      (item: IDivision) => item.division
    );
    this.currentDivision =
      this.divisionsData[this.initialValueForDivisionsData];
    this.adalLoginService.getRawData().then((data) => {
      this.rawData = normalizeRawJson(data);
      this.setDataByDivision(null);
    });
  }

  public setDataByDivision(division: number): void {
    this.dataSubject.next(null);
    if (division !== null) {
      this.currentDivision = this.divisionsData[division];
    } else {
      if (!this.currentDivision) {
        return;
      }
    }

    let dataByDivision: IDivision = GraphDataMock.divisions.find(
      (dataOfDivision: IDivision) => dataOfDivision.division === 'ICL'
    );
    this.verticalBarData = dataByDivision ? dataByDivision.data : null;

    setTimeout(() => {
      if (this.rawData) {
        const graphData = [];

        const SalesNav = this.rawData[this.currentDivision].sales;
        const refactoredDataSales =
          this.dataConverter.getFullVerticalSalesGraph(SalesNav, 'Sales');
        graphData[0] = {
          name: 'Sales',
          nav: refactoredDataSales,
        };
        const IONav = this.rawData[this.currentDivision].OI;
        const refactoredDataIO = this.dataConverter.getFullVerticalGraph(
          IONav,
          'OI'
        );
        // this.verticalBarData[1].nav = refactoredDataIO;
        graphData[1] = {
          name: 'OI',
          nav: refactoredDataIO,
        };

        const EBITDANav = this.rawData[this.currentDivision].EBITDA;
        const refactoredDataEBITDA = this.dataConverter.getFullVerticalGraph(
          EBITDANav,
          'EBITDA'
        );
        // this.verticalBarData[2].nav = refactoredDataEBITDA;
        graphData[2] = {
          name: 'EBITDA',
          nav: refactoredDataEBITDA,
        };

        const CFNav = this.rawData[this.currentDivision].CF;

        CFNav.nav.M = null;
        const refactoredDataCF = this.dataConverter.getFullVerticalGraph(
          CFNav,
          'CF'
        );
        graphData[3] = {
          name: 'FCF',
          nav: refactoredDataCF,
        };

        const TTPNav = this.rawData[this.currentDivision].TTP;
        const refactoredDataTTP = this.dataConverter.getFullHorizontalGraph(
          TTPNav,
          'TTP'
        );
        // this.verticalBarData[4].nav = refactoredDataTTP;
        graphData[4] = {
          name: 'Top 10 Products',
          nav: refactoredDataTTP,
        };

        const TTCNnav = this.rawData[this.currentDivision].TTC;
        const refactoredDataTTC = this.dataConverter.getFullHorizontalGraph(
          TTCNnav,
          'TTC'
        );
        // this.verticalBarData[5].nav = refactoredDataTTC;
        graphData[5] = {
          name: 'Top 10 Customers',
          nav: refactoredDataTTC,
        };

        // normalizeMapGraph
        const mapData = this.rawData[this.currentDivision].MAP;
        // this.verticalBarData[6] = mapData;
        graphData[6] = mapData;
        graphData[6].name = 'Sales by Territory';

        const PPNav = this.rawData[this.currentDivision].PP;
        // this.verticalBarData[7].data = PPNav.data;
        graphData[7] = PPNav;
        graphData[7].name = 'Potash Prices';

        const KPINav = this.rawData[this.currentDivision].KPI;
        // this.verticalBarData[8] = this.dataConverter.getKPIGraph(KPINav, 'KPI');
        graphData[8] = this.dataConverter.getKPIGraph(KPINav, 'KPI');
        graphData[8].name = 'Financial KPI';

        graphData[9] = this.rawData.ICL.SBU;

        const FCFNav = this.rawData[this.currentDivision].FCF.nav;
        // this.verticalBarData[10] = {
        //   name : 'ICL FCF',
        //   dataTable : FCFNav.slides[0].data

        graphData[10] = {
          name: 'ICL FCF',
          nav: FCFNav,
        };

        graphData[12] = {
          name: `Capex ${
            this.rawData[this.currentDivision].CAPEX.slides[0].data[0].year
          } ACT vs BGT`,
          options: this.dataConverter.getCapexGraph(
            this.rawData[this.currentDivision].CAPEX,
            'CAPEX'
          ).options,
        };

        const PotashAvgNav = this.rawData[this.currentDivision].PA;
        const refactoredDataPA =
          this.dataConverter.getGraphDataForPotashAvgGraph(PotashAvgNav, 'PA');
        graphData[11] = {
          name: 'Potash Avg. Price',
          nav: refactoredDataPA,
        };
        this.dataSubject.next(graphData);
      }

      // const IONav = this.rawData.ICL.
    }, 100);
  }
}
