import { Component, Input, OnInit } from '@angular/core';
import { GraphData } from 'src/app/model/data.MODEL';

@Component({
  selector: 'app-half-doughunt-graph-rtba',
  templateUrl: './half-doughunt-graph-rtba.component.html',
  styleUrls: ['./half-doughunt-graph-rtba.component.scss']
})
export class HalfDoughuntGraphRtbaComponent implements OnInit {
  @Input() data!: any;
  @Input() chartId: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
