import { Component, Input, OnInit } from '@angular/core';
import { GraphData } from 'src/app/model/data.MODEL';

@Component({
  selector: 'app-cf-section',
  templateUrl: './cf-section.component.html',
  styleUrls: ['./cf-section.component.scss'],
})
export class CfSectionComponent implements OnInit {
  @Input() verticalBarData!: GraphData;
  constructor() {}

  ngOnInit(): void {}
}
