import { Component, Input, OnInit } from '@angular/core';
import { GraphData } from '../../../app/model/data.MODEL';

@Component({
  selector: 'app-sales-section',
  templateUrl: './sales-section.component.html',
  styleUrls: ['./sales-section.component.scss'],
})
export class SalesSectionComponent implements OnInit {
  @Input() verticalBarData!: GraphData;

  constructor() {}
  ngOnInit(): void {}

}
