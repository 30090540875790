import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'forecast'
})
export class ForecastPredictorPipe implements PipeTransform {

  transform(current, cutoff): string {
    if(current <= cutoff) {
      return 'Q' + current + 'A';
    }
    else 
    return 'Q' + current + 'F';
  }

}
