<div class="graph-section-container">
  <div class="header-section">
    <h2>{{ tableName }}</h2>
    <div *ngIf="hasDropdown" class="dropdown-oi-container">
      <button (click)="toggleDropdownOi()" class="arrow">
        <img
          [ngClass]="{ 'is-dropdown-open': isDropdownOpen }"
          src="./assets/chevron.svg"
          alt="dropdown oi select options arrow"
        />
      </button>
      <div *ngIf="isDropdownOpen" class="buttons-dropdwon">
        <button *ngFor="let btn of btns" (click)="changeDataByDropdown(btn.id)">
          {{ btn.name }}
        </button>
      </div>
    </div>
    <div class="time-switcher-container">
      <form>
        <label
          *ngFor="let tab of timeTabs; let index = index"
          class="radio-container"
          [class.disabled]="tab.disabled"
        >
          <span>{{ tab.name }}</span>
          <input
            (change)="toggleUi(index, $event)"
            type="radio"
            [checked]="currentTab === index"
            name="timesteps"
            [value]="tab"
            [disabled]="tab.disabled"
          />
          <span class="checkmark input-table"></span>
        </label>
      </form>
    </div>
  </div>
  <div class="body-section">
    <ng-container *ngIf="currentData.nav; else flatData">
      <ngx-slick-carousel
        *ngIf="currentData !== null"
        class="carousel"
        #slickModal="slick-carousel"
        [config]="
          sliderCountHandle(
            currentData.nav[timeTabs[currentTab].name].slides.length
          )
        "
        (beforeChange)="afterChange($event)"
      >
        <div
          *ngFor="
            let slide of currentData.nav[timeTabs[currentTab].name].slides;
            trackBy: trackByFunc;
            let index = index
          "
          ngxSlickItem
          class="slider"
        >
          <ng-container
            *ngTemplateOutlet="
              itemTemplate;
              context: {
                $implicit: slide,
                moreData: slide.moreData
                  ? slide.moreData[currentIndexes[index]]
                  : null,
                id: index
              }
            "
          >
          </ng-container>
        </div>
      </ngx-slick-carousel>
    </ng-container>
    <ng-template #flatData>
      <ng-container
        *ngTemplateOutlet="itemTemplate; context: { $implicit: currentData }"
      >
      </ng-container>
    </ng-template>
  </div>
</div>
