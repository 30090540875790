<div class="welcome-page">
    <div class="header">
        <h2>Welcome</h2>
    </div>

    <div class="content">
        <p>This application/website contains highly confidential information regarding ICL Group Ltd.</p>
        <p>By checking the box you agree to maintain all data presented to you in strict confidence and not to make any use of such data in violation of any applicable security laws or any other insider trading policy.</p>

        <div class="icons-container">
            <div>
                <img src="./assets/screenshots-not-allowed-icon.svg" alt="screenshots-icon">
                <p>Screenshots are not allowed</p>
            </div>
            <div>
                <img src="./assets/forbidden-to-share-info-icon.svg" alt="info-share-icon">
                <p>It is forbidden to share information outside ICL</p>
            </div>
        </div>

        <div class="checkbox-container">
            <input type="checkbox" [(ngModel)]="termsAccepted" id="acceptTerms"/>
            <label for="acceptTerms">I Accept the <a href="/terms">terms of use</a></label>
            
        </div>
    </div>

    <div class="footer">
        <button [disabled]="!termsAccepted" (click)="login()">Continue</button>
    </div>
</div>