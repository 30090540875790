import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-multi-bar-chart-rtba',
  templateUrl: './multi-bar-chart-rtba.component.html',
  styleUrls: ['./multi-bar-chart-rtba.component.scss']
})
export class MultiBarChartRtbaComponent implements OnInit {

  @Input() recordData: any;
  @Input() symbolForBar: string;
  constructor() { }

  ngOnInit(): void {
  }
  public getChangeWith(change: number) : number {
    return change > 100 ? 80 : Math.abs(change*0.8);
  }
}
