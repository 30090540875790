
<app-section-layout [labelToSlides]="['LY','BGT']" [itemTemplate]="dataTableAndMultiBarChartEl" [hasDropdown]="true" [dataForChart]="oiByBsuData"></app-section-layout>
<ng-template #dataTableAndMultiBarChartEl let-dataTable>
<div *ngIf="SBUSectors" class="data-table-multi-charts-container">
    <div class="content-header">
        <div class="header-table">
        </div>
        <span class="ACT">ACT</span>
        <span class="TGRT">{{dataTable.name}}</span>
    </div>
    <ng-container *ngIf="SBUSectors && SBUSectors.length > 0">
    <div class="sector-container" *ngFor="let sector of dataTable.data; let index = index">
        <span class="sector-title">{{sector.division}}</span>
        <div *ngIf="dataTable.data[index]" class="sector-data-container">
        <div *ngFor="let data of dataTable.data[index].rows" class="table-data-container">
        <div class="content">
            <div class="title-table">
                <span class="title">{{data.subDivision}}</span>
            </div>
            <span class="ACT">{{data.actual}}</span>
            <span class="TGRT">{{data.value}}</span>
        </div>
            <app-multi-bar-chart-rtba symbolForBar="%" [recordData]="data"></app-multi-bar-chart-rtba>
        </div>
    </div>
  </div>
</ng-container>
</div>
</ng-template>
