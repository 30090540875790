import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { GraphData, SlideData } from '../../../app/model/data.MODEL';

@Component({
  host: {
    '(document:click)': 'onClick($event)',
  },
  selector: 'app-section-layout',
  templateUrl: './section-layout.component.html',
  styleUrls: ['./section-layout.component.scss'],
})
export class SectionLayoutComponent implements OnInit {
  public timeTabs: any[];
  public paginatorIndex: number = 0;
  public currentTab = 0;
  public isDropdownOpen: boolean;
  public dropdownIndex = 0;
  public tableName = '';
  public currentData: any;
  @Input()
  itemTemplate!: TemplateRef<any>;

  @Input()
  dataForChart!: GraphData;

  @Input()
  hasDropdown: boolean;

  @Output() dataChanged = new EventEmitter();
  @Output() onAfterChange = new EventEmitter();

  @Input() maxIndexes: number[];
  @Input() currentIndexes: number[];
  @Output() maxIndexesChange = new EventEmitter();

  @Input() labelToSlides = ['LY', '', 'BGT'];
  options: any;

  public btns: any[] = [];
  public btnKeys: string[] = [];
  public slideConfig: any = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    dots: true,
    initialSlide: 0,
  };

  constructor(private _eref: ElementRef, private cdr: ChangeDetectorRef) {}

  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
    }
  }
  public ngOnInit(): void {
    this.slideConfig.customPaging = function (slider: any, i: number): string {
      if (this.labelToSlides)
        return `<button class="paginator" (click)="handlePaginatorChart(${
          i + 1
        })"></button><span class="paginator-label">${
          this.labelToSlides[i]
        }</span>`;
    }.bind(this);
    if (this.hasDropdown) {
      this.btns = Object.keys((this.dataForChart as any).sbu).map((item) => {
        return {
          name: item,
          id: item,
        };
      });
      this.initTimeTabs((this.dataForChart as any).sbu[this.btns[0].id]);
    } else {
      this.initTimeTabs(this.dataForChart);
    }
  }

  afterChange(event) {
    this.onAfterChange.emit(event);
  }

  changeMaxIndexes() {
    if (this.timeTabs) {
      const navName = this.timeTabs[this.currentTab]?.name;
      if (navName) {
        const slides = this.currentData.nav[navName].slides;
        if (slides && slides.length > 0) {
          const tempIndexes = [0, 0, 0];
          slides.forEach((slide, i) => {
            tempIndexes[i] =
              slide.options?.series[0]?.data?.filter((x) => x.value !== null)
                .length - 1;
          });
          this.maxIndexesChange.emit(tempIndexes);
        }
      }
    }
  }

  initTimeTabs(dataToInit) {
    this.currentData = dataToInit;
    if (dataToInit.nav) {
      this.timeTabs = Object.keys(dataToInit.nav).map((item) => ({
        name: item,
        disabled: dataToInit.nav[item] == null,
      }));
      if (this.timeTabs[0].disabled) {
        this.currentTab = 1;
      }
    }
    this.tableName = dataToInit.name;

    this.changeMaxIndexes();
  }

  changeDataByDropdown(dataName) {
    this.isDropdownOpen = false;
    this.initTimeTabs((this.dataForChart as any).sbu[dataName]);
  }

  public toggleDropdownOi(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  public sliderCountHandle(count: number): void {
    if (count > 1) {
      this.slideConfig.dots = true;
    } else {
      this.slideConfig.dots = false;
    }
    if (count === 3) {
      this.slideConfig.initialSlide = 1;
    }
    return this.slideConfig;
  }

  public toggleUi(index: number, $event): void {
    this.currentTab = index;
    this.changeMaxIndexes();
    this.dataChanged.emit($event);
  }

  public handlePaginatorChart(index: number): void {
    this.paginatorIndex = index;
  }

  public trackByFunc(item: SlideData): number {
    return item.id;
  }
}
