import { Component, OnInit } from '@angular/core';
import GoToTop from '../../../app/util/GoToTop';

@Component({
  selector: 'app-go-to-top-button',
  templateUrl: './go-to-top-button.component.html',
  styleUrls: ['./go-to-top-button.component.scss']
})
export class GoToTopButtonComponent extends GoToTop implements OnInit {
    
  constructor() {
    super();
  }
  ngOnInit(): void {
  }

}
