    <ul *ngIf="stocksData$ | async as stocksData" class="stocks-container">
        <li *ngFor="let stock of stocksData; let index = index" class="stock-container" [ngClass]="{'down-guesture' : stock.dayChange  < 0,'
        up-guesture' : stock.dayChange >= 0, 'is-active-stock' : stock.stockId === choosenStock}">
            <div role="button" tabindex="0" class="stocks-info"  (click)="showChartByStock(stock,index)">
                <div class="exchange-container">
                    <span *ngIf="stock.exchange">{{stock.exchange}}:</span>
                    <span>{{stock.stock}}</span>
                </div>
               <div>
                <span class="stock-container">{{stock.rate | number: '1.2-2'}}</span>
               </div>
               <div class="change-stock-container">
                <span class="mark-rate" [ngClass]="{'down-guesture' : stock.dayChange < 0,'up-guesture' : stock.dayChange >= 0}">
                {{stock.dayChange}}%    
                </span>
               </div>
                <span class="stock-time">{{stock.time}}</span>
            </div>
        </li>
    </ul>
    <div class="chartContainer">
        <div echarts id="stockChartICL" [options]="options" class="chart-container"></div>
    </div>