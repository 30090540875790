// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  dataApiEndpoint: 'https://api.rtba1.apps4.icldig.icl-group.com/pre/data/staging',
  stocksAPIUrl:
    'https://api.rtba1.apps4.icldig.icl-group.com/pre/globes-api/getAllPapers',
  histogramStockAPIUrl: `https://api.rtba1.apps4.icldig.icl-group.com/pre/globes-api/getPaperChart?paperid={{paperId}}`,
  adalConfig: {
    tenant: '802762d2-02c4-4677-98ba-54060a234204',
    clientId: 'c04e8a79-97d0-4a6a-9e7d-4d4e9731159c',
    expireOffsetSeconds: 320, //15 minutes (60-15 = 45 minutes auth)
    //redirectUri: 'http://localhost:4200/',
    popUp: false,
    cacheLocation: 'localStorage',
    resource: 'https://graph.microsoft.com',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
