<app-section-layout [itemTemplate]="salesGraph" [dataForChart]="data" [labelToSlides]="['','']"></app-section-layout>
<ng-template #salesGraph let-data let-moreData="moreData">
    <ng-container *ngIf="data.options; else salesHorizontalGraph"> 
        <div echarts [id]="this.chartId" [options]="data.options" class="chart-container"></div>
    </ng-container>
    
    <!-- <ul class="list-of-product-data-container" *ngIf="moreData">
        <li *ngFor="let product of moreData; let last = last">
            <span class="title">{{product.name}}</span>
            <span class="value">{{product.value}}</span>
        </li>
    </ul> -->
    <ng-template #salesHorizontalGraph>
        <div class="bar-container">
            <app-horizontal-bar (click)="barClick(index)" [selected]="selectedItem===index" *ngFor="let item of data.data;index as index; trackBy : trackByFn" [item]="item"></app-horizontal-bar>
        </div>
    </ng-template>
</ng-template>
