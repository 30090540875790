
<app-section-layout [itemTemplate]="dataTableAndMultiBarChartEl" [dataForChart]="halfDoughnutBarData"></app-section-layout>
<ng-template #dataTableAndMultiBarChartEl>
<div class="mitter-charts-container">
    <app-half-doughunt-graph-rtba chartId="meetingHalfDoughnutChartId" [data]="halfDoughnutBarData.otherInfo.objectives"></app-half-doughunt-graph-rtba>
    <app-half-doughunt-graph-rtba chartId="salseHalfDoughnutChartId" [data]="halfDoughnutBarData.otherInfo.formula"></app-half-doughunt-graph-rtba>
</div>
<div class="saparator"></div>
<div class="data-table-multi-charts-container">
    <div class="content-header">
        <div class="header-table">
        </div>
        <span class="ACT">ACT</span>
        <span class="TGRT">TRGT</span>
    </div>
    <div *ngFor="let data of finacialKPIData" class="table-data-container">
    <div class="content">
        <div class="title-table">
            <span class="title">{{''+data.percent+'%'}}</span>
            <span class="description">{{data.title}}</span>
        </div>
        <span class="data-table">{{data.amount}}</span>
        <span class="data-table">{{data.target}}</span>
    </div>
        <app-multi-bar-chart-rtba symbolForBar="%" [recordData]="data"></app-multi-bar-chart-rtba>
    </div>
</div>
</ng-template>