import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SplashComponent } from './splash/splash.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DevisionsHeaderComponent } from './components/devisions-header/devisions-header.component';
import { SectionLayoutComponent } from './components/section-layout/section-layout.component';
import { StocksPanelComponent } from './components/stocks-panel/stocks-panel.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BarGraphRtbaComponent } from './components/bar-graph-rtba/bar-graph-rtba.component';
import { SalesSectionComponent } from './components/sales-section/sales-section.component';
import { IoGraphComponent } from './components/io-graph/io-graph.component';
import { EditbaSectionComponent } from './components/editba-section/editba-section.component';
import { CfSectionComponent } from './components/cf-section/cf-section.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HorizontalBarChartRtbaComponent } from './components/horizontal-bar-chart-rtba/horizontal-bar-chart-rtba.component';
import { TopProductsSectionComponent } from './components/top-products-section/top-products-section.component';
import { TopClientsSectionComponent } from './top-clients-section/top-clients-section.component';
import { AdalGuard, AdalInterceptor, AdalService } from 'adal-angular4';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { MapSectionComponent } from './components/map-section/map-section.component';
import { DataTableSectionComponent } from './components/data-table-section/data-table-section.component';
import { GoToTopButtonComponent } from './components/go-to-top-button/go-to-top-button.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HalfDoughuntGraphRtbaComponent } from './components/half-doughunt-graph-rtba/half-doughunt-graph-rtba.component';
import { FinancialKpiSectionComponent } from './components/financial-kpi-section/financial-kpi-section.component';
import { MultiBarChartRtbaComponent } from './components/multi-bar-chart-rtba/multi-bar-chart-rtba.component';
import { AbsNumberPipe } from './pipes/abs-number.pipe';
import { OiBySbuSectionComponent } from './components/oi-by-sbu-section/oi-by-sbu-section.component';
import { IclFcfSectionComponent } from './components/icl-fcf-section/icl-fcf-section.component';
import { IclCapexSectionComponent } from './components/icl-capex-section/icl-capex-section.component';
import { PieGraphRTBAComponent } from './components/pie-graph-rtba/pie-graph-rtba.component';
import { PromptComponentComponent } from './components/prompt-component/prompt-component.component';
import { PwaService } from './services/pwa.service';
import { PotashAvgSectionComponent } from './components/potash-avg-section/potash-avg-section.component';
import { CheckIfNullPipe } from './pipes/check-if-null.pipe';
import { GererateChartIdPipe } from './pipes/gererate-chart-id.pipe';
import { HandleChangesForMultibarPipe } from './pipes/handle-changes-for-multibar.pipe';
import { ForecastPredictorPipe } from './pipes/quarter-forecast.pipe';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';
import { FormsModule } from '@angular/forms';
import { HorizontalBarComponent } from './components/horizontal-bar/horizontal-bar.component';
@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    SplashComponent,
    DashboardComponent,
    DevisionsHeaderComponent,
    SectionLayoutComponent,
    StocksPanelComponent,
    BarGraphRtbaComponent,
    SalesSectionComponent,
    IoGraphComponent,
    EditbaSectionComponent,
    CfSectionComponent,
    HorizontalBarChartRtbaComponent,
    TopProductsSectionComponent,
    TopClientsSectionComponent,
    LoadingPageComponent,
    MapSectionComponent,
    DataTableSectionComponent,
    GoToTopButtonComponent,
    HalfDoughuntGraphRtbaComponent,
    FinancialKpiSectionComponent,
    MultiBarChartRtbaComponent,
    AbsNumberPipe,
    OiBySbuSectionComponent,
    IclFcfSectionComponent,
    IclCapexSectionComponent,
    PieGraphRTBAComponent,
    PromptComponentComponent,
    PotashAvgSectionComponent,
    CheckIfNullPipe,
    GererateChartIdPipe,
    HandleChangesForMultibarPipe,
    ForecastPredictorPipe,
    TermsOfUseComponent,
    WelcomePageComponent,
    HorizontalBarComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SlickCarouselModule,
    FormsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
  ],
  providers: [
    [
      AdalService,
      AdalGuard,
      { provide: HTTP_INTERCEPTORS, useClass: AdalInterceptor, multi: true },
    ],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
