import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gererateChartId',
})
export class GererateChartIdPipe implements PipeTransform {
  transform(value: any): unknown {
    const id: number = Math.floor(Math.random() * 1000);
    return value + id;
  }
}
