import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkIfNull'
})
export class CheckIfNullPipe implements PipeTransform {

  transform(value: any): string {
    if(value)
    return value;
    else 
    return 'Total'

  }

}
