<div class="terms">
    <div class="header">
        <button>
            <a href="/welcome"><img src="./assets/back-arrow-icon.svg" alt="arrow-icon"></a>
        </button>
        <h1>TERMS OF USE</h1>
    </div>
    
    <div class="content">
       <h3>Welcome</h3>
       <p>Welcome to the mobile application and/or internet site found at www._________ (the “Application”) owned and operated by ICL Group Ltd., including its affiliates in its corporate group of companies (“Company”, “we”, “us”, “our”). 
        Please carefully read the following Terms of Use (the "Terms"). By using or accessing the Application, you agree to be bound by and comply with the Terms. If you do not agree to the Terms, you may not use the Application in any way. </p>
    
        <h3>About the Application</h3>
        <p>The Application presents informative content about the Company. These are accessible only to our registered and verified users visiting the Application (“Registered User”).</p>
    
        <h3>Application use and restrictions</h3>
        <p>
            Subject to these Terms, you may access the content available on the Application. 
            You are responsible for any act and omission associated with your access to and use of the Application. 
            When using the Application, you must refrain from 
        </p>
        <ul>
            <li>Violating any rules, guidelines or instructions that we may convey with respect to the Application; </li>
            <li>Interfering with or disrupting the functionality of the Application; </li>
            <li>Breaching the security of the Application or identifying any security vulnerabilities in it;</li>
            <li>Circumventing or manipulating the operation or functionality of the Application, or attempting to enable features or functionalities that are otherwise disabled, inaccessible or undocumented on the Application;</li>
            <li>Sending automated or machine generated queries; </li>
            <li>Using robots, crawlers and similar applications to scrape, harvest, collect or compile content from the Application; </li>
            <li>Submitting false, inaccurate, deceptive or misleading content;</li>
            <li>Impersonating any person or entity, or making any false statement pertaining to your identity; </li>
            <li>Collecting or processing personal information about Registered Users;</li>
            <li>Engaging in any activity that constitutes or encourages conduct that would constitute a criminal offense, give rise to civil liability or otherwise violate any applicable law, including laws governing securities or privacy.</li>
        </ul> 
    </div>
</div>
