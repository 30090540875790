
<app-section-layout [itemTemplate]="dataTableAndMultiBarChartEl" [dataForChart]="iclFcfData"></app-section-layout>
<ng-template #dataTableAndMultiBarChartEl let-data>
<div class="data-table-multi-charts-container">
    <div *ngFor="let item of data.data" class="table-data-container">
    <div class="content">
        <div class="title-table">
            <span class="title">{{item.title}}</span>
            <span class="description">{{item.description}}</span>
        </div>
        <span class="ACT">{{item.ACT}}</span>
        <span class="TGRT">{{item.TGRT}}</span>
    </div>
        <app-multi-bar-chart-rtba [recordData]="item" marker="" ></app-multi-bar-chart-rtba>
    </div>
</div>
</ng-template>
