const navEnumTypeSeven = {
  "Current Month": "M",
  "Current Quarter": "QTD",
  "Year to date": "YTD",
  YTD: "YTD",
  QTD: "QTD",
  M: "M",
  Q: "QTD",
  Y: "YTD",
};

const GraphTypes = {
  vertical: 0,
  horizontal: 1,
  map: 2,
  table: 3,
  pie: 4,
  noslides: 5,
  noslidesAndNoNav: 6,
};

const divisions = ["ICL", "IP", "POTASH", "PS", "SF"];

const divisionNames = {
  ICL: "ICL",
  "Industrial products": "IP",
  "Potash & Magnesium": "POTASH",
  "Phosphate Solution": "PS",
  "Innovative Agriculture Solutions": "SF",
  "Phosphate Specialties": "PS",
  IP: "IP",
  IAS: "SF",
  POTASH: "POTASH",
  Potash: "POTASH",
  PS: "PS",
  SF: "SF",
};

const monthToQuarter = (month) => {
  if (month % 3 === 0) {
    return month / 3;
  } else {
    return Math.ceil(month / 3);
  }
};

const getSlidesForNavObject = (graphType) => {
  if (graphType == GraphTypes.vertical) {
    return [
      {
        title: "ly",
        data: [],
      },
      {
        title: "mid",
        data: [],
      },
      {
        title: "bud",
        data: [],
      },
    ];
  }
  if (graphType == GraphTypes.horizontal) {
    return [
      {
        title: "map",
        data: [],
      },
      {
        title: "mid",
        data: [],
      },
    ];
  }
  if (graphType == GraphTypes.noslides || GraphTypes.noslidesAndNoNav) {
    return [
      {
        title: "mid",
        data: [],
      },
    ];
  }
};

const getNavObjectForGraph = (graphName, graphType, data) => {
  let defaultValues = {};
  if (data) {
    defaultValues["M"] = data.find((c) => c["FL_CODE"] == "M")
      ? data.find((c) => c["FL_CODE"] == "M")["PE_MONTH"]
      : undefined;
    defaultValues["QTD"] = data.find((c) => c["FL_CODE"] == "QTD")
      ? data.find((c) => c["FL_CODE"] == "QTD")["PE_MONTH"]
      : undefined;
    defaultValues["YTD"] = data.find((c) => c["FL_CODE"] == "YTD")
      ? data.find((c) => c["FL_CODE"] == "YTD")["PE_MONTH"]
      : undefined;
  }

  switch (graphType) {
    case GraphTypes.noslidesAndNoNav: {
      return {
        name: graphName,
        slides: getSlidesForNavObject(graphType),
      };
    }
    default: {
      return {
        name: graphName,
        nav: {
          M: {
            slides: getSlidesForNavObject(graphType),
            defaultValue: defaultValues["M"],
          },
          QTD: {
            slides: getSlidesForNavObject(graphType),
            defaultValue: monthToQuarter(defaultValues["QTD"]),
          },
          YTD: {
            slides: getSlidesForNavObject(graphType),
            defaultValue:
              graphName == "CF"
                ? monthToQuarter(defaultValues["YTD"])
                : defaultValues["YTD"],
          },
        },
      };
    }
  }
};

const addNavObjectsToDivisionMap = (
  divisionMap,
  graphName,
  graphType,
  data
) => {
  if (!divisionMap[divisions[0]][graphName]) {
    divisions.forEach((division) => {
      let filteredData;
      if (data) {
        if (division === "SF") {
          filteredData = data.filter(
            (innerData) =>
              innerData["DIVISION_DESC"] === "IAS" &&
              innerData["REGION"] === "X"
          );
        } else {
          filteredData = data.filter(
            (innerData) =>
              innerData["DIVISION_DESC"] === division &&
              innerData["REGION"] === "X"
          );
        }
      }
      divisionMap[division][graphName] = getNavObjectForGraph(
        graphName,
        graphType,
        filteredData
      );
    });
  }
};

const normalizeFCFGraph = (fcfGraphFlat, graphName, divisionMap) => {
  addNavObjectsToDivisionMap(divisionMap, graphName, GraphTypes.noslides);

  const dataArrayQTD = [];
  const dataArrayYTD = [];
  fcfGraphFlat.forEach((flatItem) => {
    if (flatItem.FL_CODE === "QTD") {
      dataArrayQTD.push({
        change: flatItem.AMOUNT_MILION,
        title: flatItem.FREE_FIELD1,
      });
    }
    if (flatItem.FL_CODE === "YTD") {
      dataArrayYTD.push({
        change: flatItem.AMOUNT_MILION,
        title: flatItem.FREE_FIELD1,
      });
    }
  });
  Object.keys(divisionMap).forEach((key) => {
    divisionMap[key][graphName].nav.M = null;
    divisionMap[key][graphName].nav.QTD.slides[0].data = dataArrayQTD;
    divisionMap[key][graphName].nav.YTD.slides[0].data = dataArrayYTD;
  });
};

const normalizeMapGraph = (mapGraphFlat, graphName, divisionMap) => {
  addNavObjectsToDivisionMap(divisionMap, graphName, GraphTypes.noslides);

  const sortOrder = {
    "North America": 0,
    "South America": 1,
    Europe: 2,
    Asia: 3,
    "Rest of the world": 4,
    All: 5,
  };

  const allICL = "All";

  mapGraphFlat.forEach((flatItem) => {
    if (true) {
      //flatItem.FL_CODE === 'M') {
      const currentDivision = divisionNames[flatItem.DIVISION_DESC]
        ? divisionMap[divisionNames[flatItem.DIVISION_DESC]][graphName]
        : null;
      if (!currentDivision) {
        // console.log('no such division: ' + flatItem.DIVISION_DESC + ' in item');
      }
      let currentItem = currentDivision.nav[
        flatItem.FL_CODE
      ].slides[0].data.filter((item) => {
        return (
          item.region === flatItem.REGION ||
          (item.region === allICL && flatItem.REGION === null)
        );
      })[0];
      if (!currentItem || currentItem.length === 0) {
        currentItem = {
          region: null,
          countries: [],
        };
        let slideToPush = sortOrder[flatItem.REGION];
        if (slideToPush == undefined) {
          slideToPush = 5;
        }
        currentDivision.nav[flatItem.FL_CODE].slides[0].data[slideToPush] =
          currentItem;
      }
      //currentItem = currentDivision.nav[navType].slides[0].data[currentMonth - 1];
      currentItem.region = flatItem.REGION ? flatItem.REGION : allICL;
      currentItem.countries.push({
        name: flatItem.FREE_FIELD1,
        value: flatItem.AMOUNT_MILION,
        percent: flatItem.PERCENT1,
      });
    }
  });

  Object.keys(divisionMap).forEach((key) => {
    ["M", "QTD", "YTD"].forEach((navItem) => {
      divisionMap[key][graphName].nav[navItem].slides[0].data.forEach(
        (mapData) => {
          if (mapData.region === allICL) {
            const countries = mapData.countries;
            const sorted = countries.sort((itemA, itemB) => {
              return itemB.value - itemA.value;
            });
            mapData.countries = sorted.slice(0, 11);
          }
        }
      );
    });
  });
};

const normalizeKPIGraph = (kpiGraphFlat, graphName, divisionMap) => {
  addNavObjectsToDivisionMap(
    divisionMap,
    graphName,
    GraphTypes.noslidesAndNoNav
  );
  kpiGraphFlat.forEach((flatItem) => {
    if (flatItem.FL_CODE === "YTD" || flatItem.FL_CODE === "Y99") {
      //FIXME: remove Y99
      const currentDivision = divisionNames[flatItem.DIVISION_DESC]
        ? divisionMap[divisionNames[flatItem.DIVISION_DESC]][graphName]
        : null;
      if (!currentDivision) {
        // console.log('no such division: ' + flatItem.DIVISION_DESC + ' in item');
      }
      if (flatItem.AC_DESC === "KPI") {
        currentDivision.otherInfo = {
          objectives: {
            title: "Meeting the Objectives",
            value: flatItem.LY_AMOUNT,
            max: 250,
          },
          formula: {
            title: "ICL Formula with threshold",
            value: flatItem.LY_AMOUNT_MILION,
            max: 200,
          },
          options: {},
        };
      } else {
        let currentItem = {
          title: flatItem.AC_DESC,
          percent: flatItem.PERCENT1,
          amount: flatItem.AMOUNT_MILION,
          target: flatItem.BUD_AMOUNT_MILION,
          change: flatItem.LY_AMOUNT_MILION,
        };
        currentDivision.slides[0].data.push(currentItem);
      }
    }
  });
};

const normalizeCFGraph = (cfGraphFlat, graphName, divisionMap) => {
  addNavObjectsToDivisionMap(
    divisionMap,
    graphName,
    GraphTypes.vertical,
    cfGraphFlat
  );
  const defaultValues = getDefaultValues(cfGraphFlat);
  cfGraphFlat.forEach((flatItem) => {
    const currentDivision = divisionNames[flatItem.DIVISION_DESC]
      ? divisionMap[divisionNames[flatItem.DIVISION_DESC]][graphName]
      : null;
    if (!currentDivision) {
      // console.log('no such division: ' + flatItem.DIVISION_DESC + ' in item');
    }
    const navType = navEnumTypeSeven[flatItem.FL_CODE];
    const currentMonth =
      navType === "QTD" ? monthToQuarter(flatItem.PE_MONTH) : flatItem.PE_MONTH;

    const defaultValue =
      defaultValues[divisionNames[flatItem.DIVISION_DESC]][navType];
    const LYSlide = checkOrReturnExistingSlide(
      currentDivision.nav[navType].slides[0].data,
      currentMonth
    );

    if (LYSlide) {
      const MIDSlide = checkOrReturnExistingSlide(
        currentDivision.nav[navType].slides[1].data,
        currentMonth
      );
      const BUDSlide = checkOrReturnExistingSlide(
        currentDivision.nav[navType].slides[2].data,
        currentMonth
      );
      if (flatItem.FREE_FIELD1 === "FCF") {
        if (!LYSlide.amount) {
          LYSlide.amount = flatItem.LY_AMOUNT_MILION;
        }
        if (!MIDSlide.amount) {
          MIDSlide.amount =
            (flatItem.REGION === "X" || flatItem.PE_MONTH < defaultValue) &&
            flatItem.AMOUNT_MILION == 0
              ? flatItem.AMOUNT_MILION
              : flatItem.AMOUNT_MILION
              ? flatItem.AMOUNT_MILION
              : null;
        }
        if (!BUDSlide.amount) {
          BUDSlide.amount = flatItem.BUD_AMOUNT_MILION;
        }
        if (!LYSlide.lyAmount) {
          LYSlide.lyAmount =
            flatItem.REGION !== "X" && flatItem.LY_AMOUNT_MILION;
        }
        if (!BUDSlide.lyAmount) {
          BUDSlide.lyAmount =
            flatItem.REGION !== "X" && flatItem.BUD_AMOUNT_MILION;
        }
        if (flatItem.REGION === "X") {
          LYSlide.amount = flatItem.LY_AMOUNT_MILION;
          MIDSlide.amount = flatItem.AMOUNT_MILION;
          BUDSlide.amount = flatItem.BUD_AMOUNT_MILION;
          MIDSlide.percent = flatItem.PERCENT3;
          LYSlide.percent = flatItem.PERCENT1;
          BUDSlide.percent = flatItem.PERCENT2;
        } else {
          if (
            currentMonth < defaultValue ||
            currentMonth > defaultValue ||
            !defaultValue
          ) {
            LYSlide.percent = flatItem.PERCENT1;
            BUDSlide.percent = flatItem.PERCENT2;
            MIDSlide.percent = flatItem.PERCENT3;
            LYSlide.lyAmount = false;
            BUDSlide.lyAmount = false;
          } else {
            LYSlide.lyAmount = flatItem.LY_AMOUNT_MILION;
            BUDSlide.lyAmount = flatItem.BUD_AMOUNT_MILION;
          }
        }
      } else {
        const lySlideMoreDataIndex = LYSlide.moreData.findIndex(
          (data) => data.name === flatItem.FREE_FIELD1
        );
        const midSlideMoreDataIndex = MIDSlide.moreData.findIndex(
          (data) => data.name === flatItem.FREE_FIELD1
        );
        const budSlideMoreDataIndex = BUDSlide.moreData.findIndex(
          (data) => data.name === flatItem.FREE_FIELD1
        );
        if (lySlideMoreDataIndex > -1) {
          if (flatItem.REGION === "X") {
            LYSlide.moreData[lySlideMoreDataIndex] = {
              name: flatItem.FREE_FIELD1,
              value: flatItem.AMOUNT_MILION,
              lastYearValue: flatItem.LY_AMOUNT_MILION,
            };
          }
        } else {
          LYSlide.moreData.push({
            name: flatItem.FREE_FIELD1,
            value: flatItem.AMOUNT_MILION,
            lastYearValue: flatItem.LY_AMOUNT_MILION,
          });
        }
        if (midSlideMoreDataIndex > -1) {
          if (flatItem.REGION === "X") {
            MIDSlide.moreData[midSlideMoreDataIndex] = {
              name: flatItem.FREE_FIELD1,
              value: flatItem.AMOUNT_MILION,
            };
          }
        } else {
          MIDSlide.moreData.push({
            name: flatItem.FREE_FIELD1,
            value: flatItem.AMOUNT_MILION,
          });
        }
        if (budSlideMoreDataIndex > -1) {
          if (flatItem.REGION === "X") {
            BUDSlide.moreData[budSlideMoreDataIndex] = {
              name: flatItem.FREE_FIELD1,
              value: flatItem.AMOUNT_MILION,
              lastYearValue: flatItem.BUD_AMOUNT_MILION,
            };
          }
        } else {
          BUDSlide.moreData.push({
            name: flatItem.FREE_FIELD1,
            value: flatItem.AMOUNT_MILION,
            lastYearValue: flatItem.BUD_AMOUNT_MILION,
          });
        }
      }
    } else {
      if (flatItem.FREE_FIELD1 === "FCF") {
        currentDivision.nav[navType].slides[0].data.push({
          year: flatItem.PE_YEAR,
          month: currentMonth,
          amount: flatItem.LY_AMOUNT_MILION,
          percent:
            flatItem.REGION !== "X" && defaultValue == currentMonth
              ? null
              : flatItem.PERCENT1,
          lyAmount: flatItem.REGION === "X" && flatItem.LY_AMOUNT_MILION,
        });
        currentDivision.nav[navType].slides[1].data.push({
          year: flatItem.PE_YEAR,
          month: currentMonth,
          amount:
            (flatItem.REGION === "X" || flatItem.PE_MONTH < defaultValue) &&
            flatItem.AMOUNT_MILION == 0
              ? flatItem.AMOUNT_MILION
              : flatItem.AMOUNT_MILION
              ? flatItem.AMOUNT_MILION
              : null,
          percent:
            flatItem.REGION !== "X" && defaultValue == currentMonth
              ? null
              : flatItem.PERCENT3,
        });
        currentDivision.nav[navType].slides[2].data.push({
          year: flatItem.PE_YEAR,
          month: currentMonth,
          amount: flatItem.BUD_AMOUNT_MILION,
          percent:
            flatItem.REGION !== "X" && defaultValue == currentMonth
              ? null
              : flatItem.PERCENT2,
          lyAmount: flatItem.REGION === "X" && flatItem.BUD_AMOUNT_MILION,
        });
      } else {
        currentDivision.nav[navType].slides[0].data.push({
          year: flatItem.PE_YEAR,
          month: currentMonth,
          moreData: [
            {
              name: flatItem.FREE_FIELD1,
              value: flatItem.AMOUNT_MILION,
              lastYearValue: flatItem.LY_AMOUNT_MILION,
            },
          ],
        });
        currentDivision.nav[navType].slides[1].data.push({
          year: flatItem.PE_YEAR,
          month: currentMonth,
          moreData: [
            {
              name: flatItem.FREE_FIELD1,
              value: flatItem.AMOUNT_MILION,
            },
          ],
        });
        currentDivision.nav[navType].slides[2].data.push({
          year: flatItem.PE_YEAR,
          month: currentMonth,
          moreData: [
            {
              name: flatItem.FREE_FIELD1,
              value: flatItem.AMOUNT_MILION,
              lastYearValue: flatItem.BUD_AMOUNT_MILION,
            },
          ],
        });
      }
    }
  });
};

const getDefaultValues = (graphData) => {
  const defaultValues = { ICL: {}, IP: {}, POTASH: {}, PS: {}, SF: {} };
  graphData
    .filter((item) => {
      return item.REGION == "X";
    })
    .forEach((item) => {
      const division = divisionNames[item.DIVISION_DESC];
      const nav = navEnumTypeSeven[item.FL_DESC];
      const month =
        nav === "QTD" ? monthToQuarter(item.PE_MONTH) : item.PE_MONTH;
      defaultValues[division][nav] = month;
    });
  return defaultValues;
};

const normalizeVerticalGraph = (
  verticalGraphDataFlat,
  graphName,
  divisionMap
) => {
  addNavObjectsToDivisionMap(
    divisionMap,
    graphName,
    GraphTypes.vertical,
    verticalGraphDataFlat
  );
  const defaultValues = getDefaultValues(verticalGraphDataFlat);
  verticalGraphDataFlat.forEach((flatItem) => {
    const currentDivision = divisionNames[flatItem.DIVISION_DESC]
      ? divisionMap[divisionNames[flatItem.DIVISION_DESC]][graphName]
      : null;
    const navType = navEnumTypeSeven[flatItem.FL_DESC];
    if (currentDivision && flatItem.hasOwnProperty("AMOUNT_MILION")) {
      const currentMonth =
        navType === "QTD"
          ? monthToQuarter(flatItem.PE_MONTH)
          : flatItem.PE_MONTH;
      const defaultValue =
        defaultValues[divisionNames[flatItem.DIVISION_DESC]][navType];
      const LYSlide = checkOrReturnExistingSlide(
        currentDivision.nav[navType].slides[0].data,
        currentMonth
      );
      if (LYSlide) {
        const MIDSlide = checkOrReturnExistingSlide(
          currentDivision.nav[navType].slides[1].data,
          currentMonth
        );
        const BUDSlide = checkOrReturnExistingSlide(
          currentDivision.nav[navType].slides[2].data,
          currentMonth
        );
        if (!LYSlide.lyAmount) {
          LYSlide.lyAmount =
            flatItem.REGION !== "X" && flatItem.LY_AMOUNT_MILION;
        }
        if (!BUDSlide.lyAmount) {
          BUDSlide.lyAmount =
            flatItem.REGION !== "X" && flatItem.BUD_AMOUNT_MILION;
        }
        if (flatItem.REGION === "X") {
          LYSlide.amount = flatItem.LY_AMOUNT_MILION;
          MIDSlide.amount = flatItem.AMOUNT_MILION;
          MIDSlide.percent = flatItem.PERCENT3;
          LYSlide.percent = flatItem.PERCENT1;
          BUDSlide.percent = flatItem.PERCENT2;
          BUDSlide.amount = flatItem.BUD_AMOUNT_MILION;
        } else {
          if (
            currentMonth < defaultValue ||
            currentMonth > defaultValue ||
            !defaultValue
          ) {
            LYSlide.percent = flatItem.PERCENT1;
            BUDSlide.percent = flatItem.PERCENT2;
            MIDSlide.percent = flatItem.PERCENT3;
            LYSlide.lyAmount = false;
            BUDSlide.lyAmount = false;
          } else {
            LYSlide.lyAmount = flatItem.LY_AMOUNT_MILION;
            BUDSlide.lyAmount = flatItem.BUD_AMOUNT_MILION;
          }
        }
      } else {
        currentDivision.nav[navType].slides[1].data.push({
          year: flatItem.PE_YEAR,
          month: currentMonth,
          amount:
            (flatItem.REGION === "X" || flatItem.PE_MONTH < defaultValue) &&
            flatItem.AMOUNT_MILION == 0
              ? flatItem.AMOUNT_MILION
              : flatItem.AMOUNT_MILION
              ? flatItem.AMOUNT_MILION
              : null,
          percent: flatItem.PERCENT3,
        });
        if (currentMonth === defaultValue) {
          currentDivision.nav[navType].slides[0].data.push({
            year: flatItem.PE_YEAR,
            month: currentMonth,
            amount: flatItem.LY_AMOUNT_MILION,
            percent:
              flatItem.REGION !== "X" && defaultValue == currentMonth
                ? null
                : flatItem.PERCENT1,
            lyAmount: flatItem.REGION !== "X" && flatItem.LY_AMOUNT_MILION,
          });
          currentDivision.nav[navType].slides[2].data.push({
            year: flatItem.PE_YEAR,
            month: currentMonth,
            amount: flatItem.BUD_AMOUNT_MILION,
            percent:
              flatItem.REGION !== "X" && defaultValue == currentMonth
                ? null
                : flatItem.PERCENT2,
            lyAmount: flatItem.REGION !== "X" && flatItem.BUD_AMOUNT_MILION,
          });
        } else {
          currentDivision.nav[navType].slides[0].data.push({
            year: flatItem.PE_YEAR,
            month: currentMonth,
            amount: flatItem.LY_AMOUNT_MILION,
            percent:
              flatItem.REGION !== "X" && defaultValue == currentMonth
                ? null
                : flatItem.PERCENT1,
            lyAmount: flatItem.REGION === "X" && flatItem.LY_AMOUNT_MILION,
          });
          currentDivision.nav[navType].slides[2].data.push({
            year: flatItem.PE_YEAR,
            month: currentMonth,
            amount: flatItem.BUD_AMOUNT_MILION,
            percent:
              flatItem.REGION !== "X" && defaultValue == currentMonth
                ? null
                : flatItem.PERCENT2,
            lyAmount: flatItem.REGION === "X" && flatItem.BUD_AMOUNT_MILION,
          });
        }
      }
    }
  });
};

const checkOrReturnExistingSlide = (data, month) => {
  const existingSlideArray = data.filter((slide) => slide.month === month);
  if (existingSlideArray.length) {
    return existingSlideArray[0];
  } else {
    return null;
  }
};

const normalizePotashAvgGraph = (
  verticalGraphDataFlat,
  graphName,
  divisionMap
) => {
  const currentYear =
    verticalGraphDataFlat[verticalGraphDataFlat.length - 1].CALYEAR;
  addNavObjectsToDivisionMap(divisionMap, graphName, GraphTypes.noslides);
  const currentDivision = divisionMap.ICL[graphName];
  verticalGraphDataFlat.forEach((flatItem) => {
    const navType = flatItem.TYPE;
    if (navType === "QTD" || flatItem.CALYEAR === currentYear) {
      const currentMonth = flatItem.MQ;
      currentDivision.nav[navType].slides[0].data.push({
        year: flatItem.CALYEAR,
        month: currentMonth,
        KMT: flatItem.KMT,
        CIF: flatItem.CIF,
        FOB: flatItem.FOB,
      });
    }
  });
  Object.keys(divisionMap).forEach((key) => {
    divisionMap[key][graphName] = divisionMap.ICL[graphName];
  });
};

const normalizeHorizontallGraph = (
  horizontalGraphDataFlat,
  graphName,
  divisionMap
) => {
  addNavObjectsToDivisionMap(divisionMap, graphName, GraphTypes.horizontal);
  horizontalGraphDataFlat.forEach((flatItem) => {
    const currentDivision = divisionNames[flatItem.DIVISION_NAME]
      ? divisionMap[divisionNames[flatItem.DIVISION_NAME]][graphName]
      : null;
    if (!currentDivision) {
      // console.log('no such division: ' + flatItem.SUBDIVISION_NAME + ' in item');
    }

    if (currentDivision) {
      const navItem = navEnumTypeSeven[flatItem["/BIC/GLBSDIVSN"]];
      if (navItem === "M") {
        currentDivision.nav[navItem].slides[0].data.push({
          customer_name: flatItem.CUST_NAME
            ? flatItem.CUST_NAME
            : flatItem.MATERIAL_NAME,
          quantity: flatItem.SALES_MTD,
          kmt: flatItem.QUAN_MTD,
          price: flatItem.PRICE_MTD,
        });
        currentDivision.nav[navItem].slides[1].data.push({
          customer_name: flatItem.CUST_NAME
            ? flatItem.CUST_NAME
            : flatItem.MATERIAL_NAME,
          quantity: flatItem.SALES_MTD,
          kmt: flatItem.QUAN_MTD,
          price: flatItem.PRICE_MTD,
        });
      }
      if (navItem === "QTD") {
        currentDivision.nav.QTD.slides[0].data.push({
          customer_name: flatItem.CUST_NAME
            ? flatItem.CUST_NAME
            : flatItem.MATERIAL_NAME,
          quantity: flatItem.SALES_QTD,
          kmt: flatItem.QUAN_QTD,
          price: flatItem.PRICE_QTD,
        });

        currentDivision.nav.QTD.slides[1].data.push({
          customer_name: flatItem.CUST_NAME
            ? flatItem.CUST_NAME
            : flatItem.MATERIAL_NAME,
          quantity: flatItem.SALES_QTD,
          kmt: flatItem.QUAN_QTD,
          price: flatItem.PRICE_QTD,
        });
      }
      if (navItem === "YTD") {
        currentDivision.nav.YTD.slides[0].data.push({
          customer_name: flatItem.CUST_NAME
            ? flatItem.CUST_NAME
            : flatItem.MATERIAL_NAME,
          quantity: flatItem.SALES_YTD,
          kmt: flatItem.QUAN_YTD,
          price: flatItem.PRICE_YTD,
        });

        currentDivision.nav.YTD.slides[1].data.push({
          customer_name: flatItem.CUST_NAME
            ? flatItem.CUST_NAME
            : flatItem.MATERIAL_NAME,
          quantity: flatItem.SALES_YTD,
          kmt: flatItem.QUAN_YTD,
          price: flatItem.PRICE_YTD,
        });
      }
    }
  });
};

const reduceDivisionNames = (divName) => {
  if (divName === "POTASH") return "P";
  if (divName === "CF") return "IAS";
  if (divName === "SF") return "IAS";
  return divName;
};

const normalizeSBUGraph = (sbuGraphDataFlat, graphName, divisionMap) => {
  const sbuMap = {
    EBITDA: "EBITDA by SBU",
    "Income from sales and services": "Sales by SBU",
    "Total Operating Income (Loss)": "OI by SBU",
  };

  const sbuObject = {};
  addNavObjectsToDivisionMap(
    divisionMap,
    graphName,
    GraphTypes.noslidesAndNoNav
  );
  sbuGraphDataFlat.forEach((flatItem) => {
    //find current selector, add if not existing

    const subDivName = sbuMap[flatItem.AC_DESC] || flatItem.AC_DESC + "by SBU";

    if (!sbuObject[subDivName]) {
      sbuObject[subDivName] = getNavObjectForGraph(
        subDivName,
        GraphTypes.horizontal
      );
    }
    const currentNav = sbuObject[subDivName].nav[flatItem.FL_CODE];
    currentNav.slides[0].name = "LY";
    currentNav.slides[1].name = "BGT";
    //add data for LY
    let divData = currentNav.slides[0].data.find(
      (item) =>
        item.division ===
        reduceDivisionNames(divisionNames[flatItem.DIVISION_DESC])
    );
    if (!divData) {
      divData = {
        division: reduceDivisionNames(divisionNames[flatItem.DIVISION_DESC]),
        rows: [],
      };
      currentNav.slides[0].data.push(divData);
    }
    divData.rows.push({
      subDivision: flatItem.SBU_DESC,
      actual: flatItem.AMOUNT_MILION,
      value: flatItem.LY_AMOUNT_MILION,
      change: flatItem.PERCENT1,
    });

    divData = currentNav.slides[1].data.find(
      (item) =>
        item.division ===
        reduceDivisionNames(divisionNames[flatItem.DIVISION_DESC])
    );
    if (!divData) {
      divData = {
        division: reduceDivisionNames(divisionNames[flatItem.DIVISION_DESC]),
        rows: [],
      };
      currentNav.slides[1].data.push(divData);
    }
    divData.rows.push({
      subDivision: flatItem.SBU_DESC,
      actual: flatItem.AMOUNT_MILION,
      value: flatItem.BUD_AMOUNT_MILION,
      change: flatItem.PERCENT2,
    });
  });

  Object.keys(divisionMap).forEach((key) => {
    divisionMap[key][graphName].sbu = sbuObject;
  });
};

const normalizePotashPrice = (dataFlat, graphName, divisionMap) => {
  let normalizedData = { data: [] };
  let minimum = dataFlat[0].CIF;
  let maximum = dataFlat[0].CIF;
  dataFlat.forEach((flatItem) => {
    //handle minmax

    if (flatItem.CIF > maximum) {
      maximum = flatItem.CIF;
    }
    if (flatItem.CIF < minimum) {
      minimum = flatItem.CIF;
    }

    let maybeItem = normalizedData.data.filter(
      (item) => item.region === flatItem["/BIC/GLBREGIN1"]
    )[0];
    if (!maybeItem) {
      maybeItem = {
        region: flatItem["/BIC/GLBREGIN1"],
        forcast: flatItem.FAIND,
        Q1A: {},
        Q2A: {},
        Q3A: {},
        Q4A: {},
        moreData: [
          {
            name: "KMT",
            Q1A: {},
            Q2A: {},
            Q3A: {},
            Q4A: {},
          },
        ],
      };
      normalizedData.data.push(maybeItem);
    }

    maybeItem["Q" + flatItem.CALQUART1 + "A"].value = flatItem.CIF;
    maybeItem["Q" + flatItem.CALQUART1 + "A"].volume = 0;
    maybeItem.moreData[0]["Q" + flatItem.CALQUART1 + "A"] = flatItem.KMT;
    //calculateAggregateVolume(maybeItem);
  });

  normalizedData.min = minimum;
  normalizedData.max = maximum;
  normalizedData.delta = maximum - minimum;

  Object.keys(divisionMap).forEach((key) => {
    divisionMap[key][graphName] = normalizedData;
  });
};

const normalizeCapexGraph = (capexGraphData, graphName, divisionMap) => {
  addNavObjectsToDivisionMap(
    divisionMap,
    graphName,
    GraphTypes.noslidesAndNoNav
  );
  capexGraphData.forEach((flatItem) => {
    const currentDivision = divisionNames[flatItem.DIVISION_DESC]
      ? divisionMap[divisionNames[flatItem.DIVISION_DESC]][graphName]
      : null;
    if (!currentDivision) {
      // console.log('no such division: ' + flatItem.SUBDIVISION_NAME + ' in item');
    }
    if (currentDivision) {
      currentDivision.slides[0].data.push({
        year: flatItem.PE_YEAR,
        month: flatItem.PE_MONTH,
        actual: flatItem.AMOUNT_MILION,
        budget: flatItem.BUD_AMOUNT_MILION,
        budgetDeltaNumber: Math.abs(flatItem.FREE_FIELD1),
        budgetDeltaPercent: Math.abs(flatItem.PERCENT2),
        isOverBudget: parseInt(flatItem.FREE_FIELD1) > 0,
        isOverMonth: flatItem.AMOUNT_MILION === 0,
      });
    }
  });
};

const calculateAggregateVolume = (item) => {
  let tempArr = [
    { name: "Q1A", value: item.Q1A.value || 0 },
    { name: "Q2A", value: item.Q2A.value || 0 },
    { name: "Q3A", value: item.Q3A.value || 0 },
    { name: "Q4A", value: item.Q4A.value || 0 },
  ];
  // const tempArr = [{...item.Q1A, name : 'Q1A'}, item.Q2A, item.Q3A, item.Q4A];
  tempArr = tempArr.sort((first, second) => {
    return first.value - second.value;
  });
  item.Q1A.volume = tempArr.findIndex((item) => item.name === "Q1A");
  item.Q2A.volume = tempArr.findIndex((item) => item.name === "Q2A");
  item.Q3A.volume = tempArr.findIndex((item) => item.name === "Q3A");
  item.Q4A.volume = tempArr.findIndex((item) => item.name === "Q4A");
};

const normalizeRawJson = (raw) => {
  let divisionMap = {
    ICL: {},
    IP: {},
    POTASH: {},
    PS: {},
    SF: {},
  };

  normalizeVerticalGraph(raw.sales, "sales", divisionMap);
  normalizeVerticalGraph(raw.oi, "OI", divisionMap);
  normalizeVerticalGraph(raw.ebitda, "EBITDA", divisionMap);
  normalizeCFGraph(raw.cf, "CF", divisionMap);
  normalizeMapGraph(raw.sales_region, "MAP", divisionMap);
  normalizeKPIGraph(raw.kpi, "KPI", divisionMap);
  normalizeFCFGraph(raw.fcf, "FCF", divisionMap);
  normalizeSBUGraph(raw.sbu, "SBU", divisionMap);
  normalizeCapexGraph(raw.capex, "CAPEX", divisionMap);
  normalizeHorizontallGraph(raw.top_customers, "TTC", divisionMap);
  normalizeHorizontallGraph(raw.top_products, "TTP", divisionMap);

  normalizePotashPrice(raw.potash_price, "PP", divisionMap);
  normalizePotashAvgGraph(raw.potash_avg, "PA", divisionMap);

  return divisionMap;
};

export default normalizeRawJson;
