import { Component, Input, OnInit } from '@angular/core';
import { GraphData } from '../../../app/model/data.MODEL';

@Component({
  selector: 'app-potash-avg-section',
  templateUrl: './potash-avg-section.component.html',
  styleUrls: ['./potash-avg-section.component.scss'],
})
export class PotashAvgSectionComponent implements OnInit {
  @Input() potashAvgChartData!: GraphData;

  constructor() {}

  ngOnInit(): void {}
}
