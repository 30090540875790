import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
} from '@angular/core';
import { EDivisions } from '../components/devisions-header/divisions.model';
import { DataService } from '../services/data.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
  public data$: Observable<any>;
  public divisionsData: string[];

  constructor(private dataService: DataService, private ref: ElementRef) {}

  ngOnInit(): void {
    this.divisionsData = this.dataService.divisionsData;
    this.data$ = this.dataService.data$;
  }

  public setCurrentDivision(value: EDivisions): void {
    this.ref.nativeElement.style.minHeight = `${this.ref.nativeElement.clientHeight}px`;
    this.dataService.setDataByDivision(value);
  }

  isLoggedIn(): boolean {
    return true;
  }

  public getName(): string {
    return '';
  }
}
