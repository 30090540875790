  enum EDivisions {
    ICL,
    IP,
    POTASH,
    PS,
    SF,
}

export { EDivisions };

