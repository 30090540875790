import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icl-fcf-section',
  templateUrl: './icl-fcf-section.component.html',
  styleUrls: ['./icl-fcf-section.component.scss']
})
export class IclFcfSectionComponent implements OnInit {
  @Input() iclFcfData!: any;
  constructor() { }

  ngOnInit(): void {}

}
