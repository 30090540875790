import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GraphData } from 'src/app/model/data.MODEL';
import * as echarts from 'echarts';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bar-graph-rtba',
  templateUrl: './bar-graph-rtba.component.html',
  styleUrls: ['./bar-graph-rtba.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarGraphRtbaComponent implements OnInit, OnDestroy {
  public myChart: any = null;
  private _destroyed$ = new Subject();
  private _dataChanged$ = new Subject();
  private _slideChanged$ = new Subject();
  @Input() data!: GraphData;
  @Input() chartId: string = '';

  maxIndexes: number[] = [0, 0, 0];
  currentIndexes: number[] = [0, 0, 0];
  globalIndex: number = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {}

  public negativeRounder(num: number): number | string {
    if (num < 0) {
      return '(' + Math.abs(num) + ')';
    } else {
      return num;
    }
  }
  onAfterChange(event) {
    this._slideChanged$.next(event);
  }
  onDataChanged(event) {
    this._dataChanged$.next(event);
  }

  onChangeMaxIndexes(newMaxIndexes) {
    this.maxIndexes = newMaxIndexes;
  }

  public onChartInit(ec) {
    setTimeout(() => {
      this.eventsOnBarChart(ec);
    });
  }

  changeGlobalIndex(index) {
    this.globalIndex = index;
    this.maxIndexes.forEach((maxIndex, i) => {
      if (index > maxIndex) {
        this.currentIndexes[i] = maxIndex;
      } else {
        this.currentIndexes[i] = index;
      }
    });
  }

  public eventsOnBarChart(ec): void {
    this.myChart = ec;
    const myChart = ec;
    let defaultBarIndex = this.data?.nav['M']?.defaultValue
      ? this.data.nav['M'].defaultValue - 1
      : this.data?.nav['QTD']?.defaultValue
      ? this.data?.nav['QTD']?.defaultValue - 1
      : myChart.getOption().series[0].data.length - 1;
    this.changeGlobalIndex(defaultBarIndex);
    this.cdr.detectChanges();
    let seriesLength = myChart.getOption().series[0].data.length;

    myChart.dispatchAction({ type: 'highlight', dataIndex: defaultBarIndex });
    myChart.dispatchAction({
      type: 'showTip',
      seriesIndex: 1,
      dataIndex: defaultBarIndex,
    });

    this._dataChanged$.pipe(takeUntil(this._destroyed$)).subscribe((e: any) => {
      const tab = e.target.labels[0].innerText;
      const newLength = myChart.getOption().series[0].data.length;
      seriesLength = newLength;
      defaultBarIndex = this.data?.nav[tab]?.defaultValue
        ? this.data.nav[tab].defaultValue - 1
        : newLength - 1;

      this.changeGlobalIndex(defaultBarIndex);
      this.cdr.detectChanges();
      myChart.dispatchAction({ type: 'downplay' });
      myChart.dispatchAction({ type: 'highlight', dataIndex: defaultBarIndex });
      myChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 1,
        dataIndex: defaultBarIndex,
      });
    });

    this._slideChanged$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((e: any) => {
        console.log(myChart.getOption());

        const maxIndex =
          myChart.getOption().series[0]?.data?.filter((x) => x.value !== null)
            .length - 1;

        this.cdr.detectChanges();
        myChart.dispatchAction({ type: 'downplay' });
        myChart.dispatchAction({
          type: 'highlight',
          dataIndex: this.globalIndex > maxIndex ? maxIndex : this.globalIndex,
        });
        myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 1,
          dataIndex: this.globalIndex > maxIndex ? maxIndex : this.globalIndex,
        });
      });

    myChart.getZr().on('click', (params) => {
      var pointInPixel = [params.offsetX, params.offsetY];
      var pointInGrid = myChart.convertFromPixel('grid', pointInPixel);
      const options = myChart.getOption();
      const indexes = options.series.map((serie) => {
        return serie.data.filter((d) => d.value != null).length;
      });
      const maxIndex = Math.max(...indexes) - 1;

      myChart.dispatchAction({
        type: 'downplay',
      });
      if (pointInGrid[0] < 0) {
        this.changeGlobalIndex(0);
        this.cdr.detectChanges();
        myChart.dispatchAction({
          type: 'highlight',
          dataIndex: 0,
        });
      } else if (pointInGrid[0] > maxIndex) {
        this.changeGlobalIndex(maxIndex);
        this.cdr.detectChanges();
        myChart.dispatchAction({
          type: 'highlight',
          dataIndex: maxIndex,
        });
      } else {
        this.changeGlobalIndex(pointInGrid[0]);
        this.cdr.detectChanges();
        myChart.dispatchAction({
          type: 'highlight',
          dataIndex: pointInGrid[0],
        });
      }
    });

    myChart.on('mousedown', (params) => {
      myChart.dispatchAction({
        type: 'downplay',
      });
      if (params.dataIndex !== undefined) {
        this.changeGlobalIndex(params.dataIndex);
        this.cdr.detectChanges();
        myChart.dispatchAction({
          type: 'highlight',
          dataIndex: params.dataIndex,
        });
      } else {
        this.changeGlobalIndex(defaultBarIndex);
        this.cdr.detectChanges();
        myChart.dispatchAction({
          type: 'highlight',
          dataIndex: defaultBarIndex,
        });
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }
}
