import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-horizontal-bar',
  templateUrl: './horizontal-bar.component.html',
  styleUrls: ['./horizontal-bar.component.scss']
})
export class HorizontalBarComponent implements OnInit {

  constructor() { }

  @Input() item : any;
  @Input() selected : boolean = false;

  ngOnInit(): void {
  }

}
