interface IDivision {
  division: string;
  data: GraphData[];
}

interface IDashboardData {
  divisions: IDivision[];
}

interface GraphData {
  options?: any;
  name: string;
  moreData?: { name: string; value: string }[];
  nav: {
    M: NavData;
    QTD: NavData;
    YTD: NavData;
  };
}

interface NavData {
  otherinfo: string;
  defaultValue?: number;
  slides: SlideData[];
}

interface SlideData {
  title: string;
  data: any;
  options?: any; //options
}

export enum GraphTypes {
  BASETABLE,
  MULTIPLEROWS,
  HELLONEARTH,
}
export enum EVolume {
  LOW = '#dade92',
  MIDDLE = '#8AC183',
  MIDHIGH = '#87c084',
  HIGH = '#6db87f',
}

interface NavData {
  otherinfo: string;
  slides: SlideData[];
}

interface SlideData {
  id: number;
  title: string;
  data: any;
  options?: any; //options
}

export interface IStocksData {
  stockId: number;
  exchange: string;
  stock: string;
  rate: number;
  dayChange: number;
  time: string;
}

export interface IStockChartData {
  stockId: string;
  lowRate: number;
  maxRate: number;
  values: number[];
}

// export interface IStockChartData {
//   normalizedStockData: any;
//   normalizedStockDataSummary: any;
// }

export interface INormalizedStockDataSummary {
  LowRate: string;
  HighRate: string;
  AverageChange: string;
  AverageVolume: string;
}

export interface IStocksSymbols {
  id: number;
  symbol: string;
  name?: string;
  exchange?: string;
}

export { IDashboardData, IDivision, GraphData, SlideData };
